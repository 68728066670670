import React, { useState } from "react";
import { faqs } from "../assets/data/faq";
import Title from "../components/Title/Title";
import Para from "../components/Title/Para";

const Faqs = () => {
  const [data1, setData1] = useState(faqs);
  const [show, setShow] = useState(false);

  let data = "";
  const handleShow = (ans) => {
    console.log(ans, "chip idd");
    data = ans;
    console.log(data, "data");

    setShow(!show);
  };

  return (
    <>
      <section id="faq" className="faqs">
        <Title title="FAQ's" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 faq-flex">
              {data1.map((faq, id) => {
                return (
                  <div className="accordion-item">
                    <button
                      className="faq-ques accordion-btn"
                      onClick={() => {
                        handleShow(faq.ans);
                      }}
                    >
                      <h4
                        style={{ width: "90%" }}
                        className="accordion-caption"
                      >
                        {faq.ques}
                      </h4>{" "}
                      <span className="accordion-icon">+</span>
                    </button>

                    {show && (
                      <div
                        align="justify"
                        style={{ width: "90%" }}
                        className="accordion-content"
                      >
                        <p>{faq.ans}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
