import React from 'react'
import { NavLink } from "react-router-dom";

import books from "../assets/images/books/books.png"

import styles from "./books.module.css"

const BookBanner = () => {
    return (
        <>
            <img align="center"  className={styles.book21} src={books}></img>
            <div className={styles.bbanner} style={{ display: 'flex'}}>
                <img style={{width:'30%'}}  className={styles.book2} src={books}></img>

                <div align="center">
                    <h2  className={styles.banner}>Check Out the Good Good Piggy Storybooks</h2>
                    <NavLink to="/books" exact>
                        <button className={styles.bbutton}>Check Out Now!</button>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default BookBanner