import React from "react";

import styles from "./books.module.css";
import { book } from "../assets/data/BookData";

//images
import books from "../assets/images/books/books.png";
import piggy from "../assets/images/books/piggy.png";
import book2 from "../assets/images/books/books2.png";
import botbanner from "../assets/images/books/banner.png";

import nas from "../assets/images/books/nas.png";
import ca from "../assets/images/books/ca.png";

import one from "../assets/images/books/Easy-to-follow/1.png";
import two from "../assets/images/books/Easy-to-follow/2.png";
import three from "../assets/images/books/Easy-to-follow/3.png";
import four from "../assets/images/books/Easy-to-follow/4.png";
import five from "../assets/images/books/Easy-to-follow/5.png";
import six from "../assets/images/books/Easy-to-follow/6.png";

const Books = () => {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.text}>
          <h1 style={{ margin: 0, padding: 0 }}>
            Unlock the secrets of money with
          </h1>
          <h1 style={{ margin: 0, padding: 0 }}>Good Good Piggy</h1>
          <h4>Engaging and Educational Adventures for Young Minds.</h4>
          <img className={styles.book21} src={book2}></img>
        </div>
        <img className={styles.book2} src={book2}></img>
      </div>
      {/* <hr /> */}

      <div style={{ width: "70%", margin: "3% 15%" }} className={styles.main}>
        <img className={styles.book} src={piggy}></img>
        <div align="center">
          <p className={styles.intro}>Money Lessons for Early Learners</p>
          <img className={styles.booksp} src={piggy}></img>
          <h4>
            A captivating book series comprising ten engaging stories that will
            spark your child's curiosity while imparting valuable lessons on
            earning, saving, entrepreneurship, spending, and other essential
            life skills.
          </h4>
        </div>
      </div>

      <div
        align="center"
        className={styles.features}
        style={{ width: "80%", margin: "5% 10%" }}
      >
        <p style={{ marginTop: "2%" }} className={styles.intro}>
          Why Choose Good Good Piggy's Books for Your Kids?
        </p>

        <div>
          <img src={one}></img>
          <img src={two}></img>
          <img src={three}></img>
          <img src={four}></img>
          <img src={five}></img>
          <img src={six}></img>
        </div>
      </div>

      <div align="center" style={{ width: "50%", margin: "5% 25%" }}>
        <p style={{ marginTop: "2%" }} className={styles.intro}>
          Join the Good Good Adventure!
        </p>
        <h6>
          Grab your copies of Money Lessons for Early Learners and embark on an
          unforgettable reading journey with your kids.
        </h6>
      </div>

      <div>
        {" "}
        <img
          src={nas}
          style={{ width: "60%", heigth: "auto", margin: "0 20%" }}
        ></img>{" "}
      </div>

      <div align="center" className={styles.cards}>
        <div className={styles.can}>
          {book?.map((item) => {
            return (
              <div className={styles.container}>
                <img src={item.img}></img>
                <hr />
                <p> {item.name}</p>
                <hr />
                <p style={{ fontSize: "14px", margin: "0px" }}>Buy Now:</p>
                <button>
                  <a href={item.kindle} target="_blank">
                    eBook
                  </a>
                </button>
                <button>
                  <a href={item.npress} target="_blank">
                    PaperBack
                  </a>
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {/* books contest div */}

      <div align="center">
        <h2 className={styles.text}>CONTEST ALERT!</h2>
        <h4 style={{ marginBottom: "0", fontWeight: "400" }}>
          <b>Five</b> lucky winners stand a chance to win two <b>FREE</b> copies
          of Good Good Piggy books.
        </h4>
        <a href="https://forms.gle/DKVVok9gZzQvg87dA" target="_blank">
          {" "}
          <button
            style={{ padding: "5px 10px", marginBottom: "20px" }}
            className={styles.bbutton}
          >
            Click to participate
          </button>
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/goodgoodpiggy.app" target="_blank">
          <img src={botbanner} className={styles.bottombanner}></img>{" "}
        </a>
      </div>
    </>
  );
};

export default Books;
