import React from 'react'
import Banner from '../components/Banner/Banner'
import About from '../components/About'
import Testimonial from '../components/Testimonial'
import Email from '../components/Email'
import Blog from '../components/Blog'
import Features from '../components/Features'
import Youtube from '../components/Youtube'
import BrandLogo from '../components/Brandlogo'
import Faqs from '../components/Faqs'
import Achievements from '../components/Achievements'
import BrandPartners from '../components/BrandPartners'
import BookBanner from './BookBanner'
import Community from '../components/CommunityForm/Community'

const Home = () => {
    return (
        <>
        <Banner />
        <Youtube />
        <Achievements/>
        <About />
        <BrandLogo />
        {/* <ServicesSection /> */}
        <BookBanner/>
        {/* <Features /> */}
        <Email />
      
        <BrandPartners/>
        <Testimonial />
        <Blog />
        <Faqs />
        <Community />
        </>
    )
}

export default Home;
