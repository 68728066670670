import React from "react";
import EventCard from "../components/EventCard";

const Events = () => {
  return (
    <>
      <div className="about-page">
        <div className="about-banner">
          <div className="container">
            <div className="row about-banner-content">
              <div className="col-12">
                <h1>Participate and Learn</h1>
                <h4 style={{ color: "#fff" }}>
                  Experience our story, our work, and our unique way to raise
                  good good kids by our amzing and exciting events.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <EventCard />
      </div>
    </>
  );
};

export default Events;
