import React, { useState, useEffect, useRef } from "react";
import ContactForm from "../components/Contact/ContactForm";
import { MdPhone } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { FiMapPin } from 'react-icons/fi';
import AOS from "aos";

const Contact = () => {

  const mounted = useRef(false);


  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <>
      <div className="contact-section container">
        <div className="row contact-content">
          <div className="contact-text-section col-lg-12 col-md-6 col-12">
            {/* <h4>Send a Message</h4> */}
            <h2 className="contact-head">Get In Touch With Us</h2>
            <p className="desc-section">
              {/* Good Good Piggy fulfills parent's desire to raise smarter kids with better habits at an early age
              Helps kids learn and earn their pocket money */}

              Good Good Piggy helps parents raise smarter kids with better habits early on, while teaching kids to earn pocket money.
            </p>
            <ul className="contact-list">
              <li className="contact-lists" data-aos="flip-left" data-aos-duration='600'>
                <div className="contact-icon">
                  <FiMapPin />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Office Address</h4>
                  <p className="text-left">
                    Registered Office : 172, 3rd Floor, Kailash Hills, East of Kailash, New Delhi 110065<br/><br/>

                    CIN : U67100DL2021PTC379888<br/><br/>

                    {/* Corporate Office : Building 10, 3rd Floor, Community Centre, East of Kailash, New Delhi 110065 */}
                  </p>
                </div>

              </li>
              <li className="contact-lists" data-aos="flip-left" data-aos-duration='800'>
                <div className="contact-icon">
                  <MdPhone />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Telephone Number</h4>
                  <p className="text-left"> +91 9144792447</p>
                </div>
              </li>
              <li className="contact-lists" data-aos="flip-left" data-aos-duration='600'>
                <div className="contact-icon">
                  <FaRegEnvelope />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Mail Address</h4>
                  <p className="text-left">hello@goodgoodpiggy.com</p>
                </div>
              </li>
            </ul>

          </div>
          <div className="contact-form-section col-lg-12 col-md-6 col-12">
            <ContactForm />
          </div>
        </div>
      </div>
      <div style = {{margin:'10%', width:'80%'}}className="container-fluid my-5">
        <div className="row">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.400942532724!2d77.24328427518978!3d28.55772067570569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce167da586f5b%3A0xa5d79c1b1239f7f9!2sGood%20Good%20Piggy!5e0!3m2!1sen!2sin!4v1687177190797!5m2!1sen!2sin"  height="450"  loading="lazy" ></iframe>
           </div>
      </div>
    </>
  );
};

export default Contact;
