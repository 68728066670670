import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Title from "./Title/Title";

import csoon from "../assets/images/books/csoon.png";

import one from "../assets/images/BrandPartners/img1.jpg";
import two from "../assets/images/BrandPartners/img2.jpg";
import three from "../assets/images/BrandPartners/img3.jpg";
import four from "../assets/images/BrandPartners/img4.png";
import five from "../assets/images/BrandPartners/img5.png";
import six from "../assets/images/BrandPartners/img6.png";
import seven from "../assets/images/BrandPartners/img7.png";
import eight from "../assets/images/BrandPartners/img8.png";
import nine from "../assets/images/BrandPartners/img9.png";
import ten from "../assets/images/BrandPartners/img10.png";

const BrandPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="brands">
      <div
        style={{
          marginLeft: "5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Title title="Brand Partners" />
        {/* <img
          style={{
            width: "5rem",
            margin: "12px 0 0 -4px",
            transform: "rotate(-20deg)",
          }}
          src={csoon}
        ></img> */}
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={one}
                  style={{ height: "50%", width: "50%", margin: "0% 25%" }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={two}
                  style={{
                    height: "40%",
                    width: "40%",
                    margin: "0% 30%",
                    borderRadius: "10px",
                  }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={three}
                  style={{
                    height: "40%",
                    width: "40%",
                    margin: "0% 30%",
                    borderRadius: "10px",
                  }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={four}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={five}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={six}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={seven}
                  style={{ height: "40%", width: "40%", margin: "0% 30%" }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={eight}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={nine}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={ten}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandPartners;
