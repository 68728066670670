import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//images
import one from "../assets/images/about/adaptive.png"
import two from "../assets/images/about/integrity.png";
import three from "../assets/images/about/lock.png";
import four from "../assets/images/about/people.png";
import five from "../assets/images/about/responsibility.png";
import six from "../assets/images/about/safety.png";
import seven from "../assets/images/about/value.png";





import styles from './ourValues.module.css';



function App() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplayspeed: 3000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div className={styles.App}>
            <h1 style={{ color: '#B555C3' }}>Our Values</h1>
            <Slider {...settings}>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={one}
                        />
                        <h3>Adaptability</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={two}
                        />
                        <h3>Integrity</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={three}
                        />
                        <h3>Safety & Protection</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={four}
                        />
                        <h3>People & Their Well Being</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={five}
                        />
                        <h3>Responsibility</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={six}
                        />
                        <h3>Trust</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={seven}
                        />
                        <h3>Value Centricity</h3>
                    </div>
                </div>



            </Slider>
        </div>
    );
}

export default App;