import React from "react";
import { NavLink } from "react-router-dom";


const NavLinks = () => {
  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" exact>
          About Us
        </NavLink>
      </li>
      
      <li>
        <NavLink to="/books" exact>
          Books
        </NavLink>
      </li>
      <li>
        <NavLink to="/about-app" exact>
            App
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="#" exact>
          Collaborate
        </NavLink>
      </li> */}
      {/* <li> */}
        {/* <a href="https://play.google.com/store/search?q=good%20good%20piggy&c=apps&hl=en&gl=US" target="_blank" >App</a> */}
        {/* <NavLink to="https://play.google.com/store/search?q=good%20good%20piggy&c=apps&hl=en&gl=US" exact>
          App
        </NavLink> */}
      {/* </li> */}
      <li>
        <NavLink to="/contact-us" exact>
          Contact Us
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="#" exact>
          Events
        </NavLink>
      </li> */}
    </ul>
  );
};

export default NavLinks;
