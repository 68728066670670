import React from "react";
import Button from "./Button";
import serviceImg from "../assets/images/service2.jpg";
// import softwareDevelopment from "../images/service/software-development.jpg";
import softwareDevelopment from "../assets/images/service/software-development.jpg";
import androidDevelopment from "../assets/images/service/android-development.jpg";
import worldwide from "../assets/images/service/worldwide-reach.jpg";
import customerCare from "../assets/images/service/customer-care.png";
import gpsVehicle from "../assets/images/service/gps-vehicle.jpg";
import Title from "./Title/Title";
import AOS from "aos";
import Para from './Title/Para'
import { Link } from 'react-router-dom'

import book2 from "../assets/images/books/books2.png";
import bookss from "../assets/images/books/b2.png";



const EventCard = () => {
  return (
    <>
      <section className="services_wrapper py-5">
        <Title title="Successfull Events" />
        <Para para="" />
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
                <div className="service-image-div">
                  <img src={book2} alt="" className="img-fluid" />
                </div>
                <div className="card-body p-3">
                  <h3>Event Name</h3>
                  <p className="text-center">
                  Diversity is our strength. It leads to innovation, new ideas and a better way of working together. We’re a team that does not differentiate on basis of caste, religion, gender, age or sexuality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={book2} alt="" className="img-fluid" />
              </div>
                <div className="card-body p-3">
                  <h3>Event Name</h3>
                  <p className="text-center">
                  Diversity is our strength. It leads to innovation, new ideas and a better way of working together. We’re a team that does not differentiate on basis of caste, religion, gender, age or sexuality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={book2} alt="" className="img-fluid" />
              </div>
                <div className="card-body p-3">
                  <h3>Event Name</h3>
                  <p className="text-center">
                  Diversity is our strength. It leads to innovation, new ideas and a better way of working together. We’re a team that does not differentiate on basis of caste, religion, gender, age or sexuality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={book2} alt="" className="img-fluid" />
              </div>
                <div className="card-body p-3">
                  <h3>Event Name</h3>
                  <p className="text-center">
                  Diversity is our strength. It leads to innovation, new ideas and a better way of working together. We’re a team that does not differentiate on basis of caste, religion, gender, age or sexuality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={book2} alt="" className="img-fluid" />
              </div>
                <div className="card-body p-3">
                  <h3>Event Name</h3>
                  <p>
                  Diversity is our strength. It leads to innovation, new ideas and a better way of working together. We’re a team that does not differentiate on basis of caste, religion, gender, age or sexuality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventCard;
