import React from "react";

const Para = ({ para }) => {
  return (
    <div className="section-para text-center pb-3 wid-50 mx-auto">
      <p>{para}</p>
    </div>
  );
};

export default Para;
