export const faqs = [
    {
        id: 1,
        ques: 'Why a digital piggy bank?',
        ans: 'The world is slowly becoming cashless so it makes more sense to teach financial skills and values in the way that our children will see and use it in their lives. We want them to be able to practice what they learn and reap its rewards.'
    },
    {
        id: 2,
        ques: 'Is my child too young to learn about money?',
        ans: 'A study from Cambridge University confirmed that basic money habits and other behaviours associated with money are formed by the age of seven. Concepts like delayed gratification and impulse control are better the sooner they are taught. Good Good Piggy is designed for kids aged 4-14 so don’t worry about that. We have designed the app to be kid-friendly.'
    },
    {
        id: 3,
        ques: 'Will learning about money make my child more demanding, spoiled and materialistic?',
        ans: 'We understand your concern. But in a nutshell, the result would be quite the opposite! Research has proven that kids are naturally curious about money and the way it works. We want to teach them in a positive and affirmative way. Our team of leading child psychologists helped us build an application whose goal is not just teaching about money but also the value of it. We want to teach saving and investing in easy-to-practice ways as well as values like patience and accountability.'
    },
    {
        id: 4,
        ques: 'I don’t see other young parents teaching this skill, then why is teaching about money important to my children?',
        ans: 'According to a study done by Cambridge University, basic money habits and behaviours associated with money are formed by the age of seven. This is why it is important to teach good financial values as a foundation for their future choices.'
    },
    {
        id: 5,
        ques: 'I don’t want everything in my child’s world to be digital. Why can’t I simply use a physical piggy bank?',
        ans: 'Physical money and digital money do not often promote the same spending behaviours. Since money is becoming increasingly digitised, it is important to teach children money values in the form that they will use it most often. We don’t want to teach offline behaviour that they will forget to use in digital transactions.'
    },
    {
        id: 6,
        ques: 'So is it just a digital wallet for my child?',
        ans: 'Good Good Piggy helps transform your child’s life, by inculcating excellent habits in your child for life! For instance, Good Good Piggy lists down “missions” for the day. The parent can choose to reward these missions as well as consistency in completing daily missions. By reiterating these everyday tasks and chores, Good Good Piggy emphasises their importance and makes the child accountable for completing them as well as giving positive reinforcement for such behaviour.'
    }
]

export const parentData = [
    {
        id: 1,
        ques: 'Pocket Money Planner',
        ans: `Streamline your kid's financial future with effortless management`
    },
    {
        id: 2,
        ques: 'Transaction Tracker',
        ans: 'Effortless monitoring and informed decision-making'
    },
    {
        id: 3,
        ques: 'Order Overview',
        ans: `Convenient access and responsible spending control`
    },
    {
        id: 4,
        ques: 'Kid Control Panel',
        ans: 'Easy tracking of all financial aspects'
    },
    {
        id: 5,
        ques: 'Goal Tracker',
        ans: 'Effective monitoring and support for financial goals'
    },
    // {
    //     id: 6,
    //     ques: 'Data Privacy & Security',
    //     ans: 'Strict data protection and trust'
    // },
    // {
    //     id: 7,
    //     ques: 'Concierge Desk',
    //     ans: `Personalised assistance for a seamless experience`
    // },
    {
        id: 8,
        ques: 'Family Hub',
        ans: 'Easy customization and management of kid details'
    },
    {
        id: 9,
        ques: 'Swift Approve',
        ans: 'Streamlined approval process to promote responsible financial habits'
    },
    {
        id: 10,
        ques: 'Connect Hub',
        ans: 'Discover events and be a part of the supportive community'
    }
]

export const kidData = [
    {
        id: 1,
        ques: 'Secret Passcodes',
        ans: `Enhanced security for account privacy`
    },
    {
        id: 2,
        ques: 'Blessings',
        ans: 'Effortlessly receive monetary blessings in your digital piggy bank'
    },
    {
        id: 3,
        ques: 'Thinking Assistant',
        ans: `Foster responsible spending and decision-making`
    },
    {
        id: 4,
        ques: 'Goal Tracker',
        ans: 'Achieve goals, develop financial discipline'
    },
    {
        id: 5,
        ques: 'Fun-tastic Shop Zone',
        ans: 'Promotes financial literacy and responsible spending'
    },
    {
        id: 6,
        ques: 'Challenge Creator',
        ans: 'Foster goal achievement and personal growth'
    },
    {
        id: 7,
        ques: 'Super Cool Dashboard',
        ans: `Engaging interface for active financial management`
    }
]

export const trasnactionTracker = [
    {
        id: 1,
        ques: 'How can I access the Transaction Tracker feature in the Good Good Piggy app',
        ans: 'To access the Transaction Tracker feature, you can usually find it in the main menu or dashboard of the Good Good Piggy app. Look for a section specifically labeled as "Transaction Tracker" or "Transaction History" to view the comprehensive list of transactions.Its for both, for parents its on their wallet & seperate for kids wallet. for kids its only their wallet.'
    },
    {
        id: 2,
        ques: 'Can I filter or search for specific transactions within the Transaction Tracker',
        ans: 'Yes, the Transaction Tracker typically provides options for filtering on the basis of time period.'
    },
    {
        id: 3,
        ques: 'Does the Transaction Tracker display both incoming and outgoing transactions',
        ans: 'Yes, the Transaction Tracker displays both incoming and outgoing transactions. It presents a complete record of all financial activities made through the Good Good Piggy app, allowing users to track both the money received and spent.'
    },
    {
        id: 4,
        ques: 'Can I view transaction details such as the date, amount, and recipient/payee',
        ans: `Absolutely! The Transaction Tracker provides transaction details, including the date, amount, and recipient/payee information. This allows users to have a clear understanding of each transaction's specifics.`
    },
    {
        id: 5,
        ques: 'Does the Transaction Tracker provide insights or reports on spending patterns or trends',
        ans: 'Feature yet to be released and currently unavailable'
    },
    {
        id: 6,
        ques: 'How far back does the Transaction Tracker store transaction history',
        ans: `The duration of stored transaction history varies depending on the app's design and storage capabilities. Typically, the Transaction Tracker stores transaction history for a reasonable period, allowing users to refer back to past activities within a specific timeframe, such as several months or a year.`
    }
]

export const orderOverview = [
    {
        id: 1,
        ques: 'How can I access the Order Overview feature in the Good Good Piggy app',
        ans: `To access the order overview in the parent dashboard, follow these steps: 

        1. Select the kid's screen from the dashboard.
        2. Click on "Past Orders." This will display the order history of the specific kid.
        3. Alternatively, from the kid's dashboard, you can directly click on "Goals" and then select "History" to view and check your past orders`
    },
    {
        id: 2,
        ques: 'Does the Order Overview display details of each order, such as the items purchased and order dates',
        ans: 'Yes, the Order Overview displays details of each order, including the items purchased and the respective order dates. This allows parents or guardians to have a comprehensive view of the orders placed by their Kid.Remember the only orders shown are the ones which were approved by the parent.'
    },
    {
        id: 3,
        ques: `Can I view the current status of my kid's orders through the Order Overview`,
        ans: `Yes, the Order Overview typically provides information about the current status of your kid's orders. You can see if an order is still processing, shipped, out for delivery, or delivered as part of app's tracking capabilities.`
    },
    {
        id: 4,
        ques: 'Are there options to filter or search for specific orders within the Order Overview',
        ans: `Depending on the app's functionality, there may be options to filter or search for specific orders within the Order Overview. You can often utilize filters like date range, order status, & Approved or not.`
    },
    {
        id: 5,
        ques: 'Can I see the total amount spent on each order in the Order Overview',
        ans: ' Yes, the Order Overview typically provides information about the total amount spent on each order.'
    },
    {
        id: 6,
        ques: 'Does the Order Overview provide information on the delivery or shipping status of the orders',
        ans: ` Yes, the Order Overview may provide information on the delivery or shipping status of the orders. You can often see the current status of each order, whether it is in transit or delivered, giving you visibility into the progress of the shipments.`
    },
    {
        id: 7,
        ques: 'Is there a way to track the shipment of orders placed through the app',
        ans: `Order tracking is currently limited to in-app updates only. However, we are actively working on expanding this feature to include notifications for order tracking on WhatsApp and via email. The launch of these additional tracking options is expected soon.`
    },
    {
        id: 8,
        ques: 'Can I rate or provide feedback on the items purchased through the Order Overview',
        ans: `To enable order tracking notifications on WhatsApp and via email, kindly reach out to us by sending a message to support@goodgoodpiggy.com. Our team will assist you with the necessary steps to set up these notifications for your orders`
    },
    {
        id: 9,
        ques: 'Are there any options to cancel or modify orders through the Order Overview',
        ans: `For assistance with order modifications, please contact our customer support team either through email at support@goodgoodpiggy.com or by calling the concierge desk number. Please note that the guidelines for modifying orders may vary depending on the specific product. Our customer support team will provide you with the necessary information and guidelines to proceed with the desired modifications.`
    }, 
    {
        id: 10,
        ques: 'How far back does the Order Overview store the order history',
        ans: ` The duration of stored order history in the Order Overview depends on the app's design and storage capabilities. Generally, the Order Overview stores order history for a reasonable period, allowing parents or guardians to refer back to past orders within a specific timeframe, such as several months or a year.`
    },

    
    
]

export const kidcontrolpanel = [
    {
        id: 1,
        ques: 'How can I access the Kid Control Panel feature in the Good Good Piggy app',
        ans: `To access the kid control panel, simply select the Kid on the parent dashboard and scroll down to the bottom of the page. You will find the kid control panel available for further management and customization of settings related to your Kid's account.`
    },
    {
        id: 2,
        ques: 'What types of items can I track through the Kid Control Panel',
        ans: ' Through the Kid Control Panel, you can track various activities associated with your Kid. This can include approvals related to orders placed by your Kid, their selection of needs and wants for a particular goal, information about amounts of money in from loved ones, and potentially other activities or features specific to the Good Good Piggy app.'
    },
    {
        id: 3,
        ques: `Can I control the list of orders placed by my kid on the app through the Kid Control Panel`,
        ans: `Yes, the Kid Control Panel typically provides a list of approvals before the order can be placed by your Kid. This allows you to keep track of the items your Kid has purchased through the Good Good Piggy app and deny any item based on your discreetion.`
    },
    {
        id: 4,
        ques: `Does the Kid Control Panel display information about my Kid's selected needs and wants`,
        ans: ` Yes, the Kid Control Panel usually displays information about your Kid's selected needs and wants. It allows you to view, contribute and approve the items or goals your Kid has identified as their desired purchases or savings targets.`
    },
    {
        id: 5,
        ques: 'How does the Kid Control Panel show the received amounts of money for my Kid',
        ans: ' The Kid Control Panel typically shows the received amounts of money for your Kid as a transaction notification. It may display the total amount received, the source of the funds, and any additional details related to the transactions.'
    },
    {
        id: 6,
        ques: 'Can I set spending limits or restrictions for my Kid through the Kid Control Panel',
        ans: ` The ability to set spending limits or restrictions for your Kid through the Kid Control Panel is possible. Check for settings related to managing your Kid's spending within settings menu as auto approvals. You may also control the same using the pocket money planner.`
    },
    {
        id: 7,
        ques: `Can I view the history or timeline of my Kid's activities within the Kid Control Panel as pending notifications?`,
        ans: ` Yes, the Kid Control Panel often allows you to view pending notifications for your Kid's activities. This feature provides a chronological record of your Kid's actions, such as requested orders, reminders, goals set, received amounts, and other tracked items as part of open notifications`
    },
    {
        id: 8,
        ques: `How secure is the Kid Control Panel in terms of protecting my Kid's information and activities`,
        ans: ` The Good Good Piggy app prioritizes the security and privacy of user information, including the activities tracked within the Kid Control Panel. The app implements security measures to protect sensitive data and adheres to relevant regulations and best practices to ensure the safety of your Kid's information.`
    },
   

    
    
]

export const goaltracker = [
    {
        id: 1,
        ques: 'How can I access the Goal Tracker feature in the Good Good Piggy app',
        ans: `To access the Goal Tracker, follow these steps:

        1. Go to the parent dashboard.
        2. Select the Kid you want to track goals for.
        3. Scroll down to the bottom of the page.
        4. Look for the goal tracker section located above the activity tracker section.
        5. Within the goal tracker section, you can track and monitor the progress of your Kid's goals.`
    },
    {
        id: 2,
        ques: 'Can I see a list of the goals my Kid has chosen from the shop',
        ans: ' Yes, the Goal Tracker typically provides a list or record of the goals your Kid has chosen from the shop. This allows you to have an overview of the items or objectives your Kid is working towards and their respective allocations.'
    },
    {
        id: 3,
        ques: `How does the Goal Tracker show the progress made towards each goal`,
        ans: ` The Goal Tracker usually shows the progress made towards each goal through visual indicators, such as progress bars. It allows you to see how much of the goal has been achieved or how close your Kid is to reaching their target in terms of pocket money allocation. You may encourage the Kid by contributing to the goal!`
    },
    {
        id: 4,
        ques: `Can I contribute or add funds towards my Kid's goals through the Goal Tracker`,
        ans: ` Yes, the Goal Tracker often provides options for you to contribute or add funds towards your Kid's goals. This allows you to support their savings efforts by adding money specifically allocated for their chosen goals.`
    },
    {
        id: 5,
        ques: `Are there any options to receive notifications or updates on the progress of my Kid's goals`,
        ans:  `Yes, the Goal Tracker may offer options to receive notifications or updates on the progress of your Kid's goals. This can include notifications when contributions are made, milestones are reached, or when goals are achieved.`
    },
    {
        id: 6,
        ques: 'Can my Kid customize or personalize their goals within the Goal Tracker',
        ans: `Your Kid has the ability to customize and personalize their goals within the Goal Tracker. This feature allows them to track goals based on their individual needs and wants. They can set specific objectives, monitor progress, and make adjustments as necessary to ensure they are on track to achieve their goals.`
    },
    {
        id: 7,
        ques: `Are there any incentives or rewards associated with achieving goals in the Goal Tracker`,
        ans: `As an upcoming feature, the app will introduce the option for incentives or rewards upon accomplishing goals. The availability and types of rewards may vary, depending on the app's features and design. These rewards can include virtual items, badges, or special recognition to celebrate milestones and successful goal achievement.`
    },
    {
        id: 8,
        ques: `How can I help my Kid stay motivated and engaged with their goals through the Goal Tracker`,
        ans: `You can actively support and encourage your child to stay motivated and engaged with their goals using the Goal Tracker. Celebrate their achievements, set achievable targets together, and have open conversations about their goals to keep them motivated on their savings journey. By understanding their needs and wants and providing the related gratification, you can foster a sense of accomplishment and persistence in reaching their objectives.`
    },
]

export const dataPrivacy = [
    {
        id: 1,
        ques: 'What measures does the Good Good Piggy app take to ensure data privacy and security',
        ans: ` The Good Good Piggy app takes several measures to ensure data privacy and security. Read more on - https://www.goodgoodpiggy.in/privacy-policy`
    },
    {
        id: 2,
        ques: 'Are there any regulations or compliance standards that the app adheres to regarding user data protection',
        ans: 'Yes, the Good Good Piggy app typically adheres to relevant regulations and compliance standards regarding user data protection.Read more on - https://www.goodgoodpiggy.com/privacy-policy'
    },
    {
        id: 3,
        ques: `How is user data stored and encrypted in the Good Good Piggy app`,
        ans: ` User data in the Good Good Piggy app is usually stored in secure databases or servers, protected by encryption. Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 4,
        ques: `Can users control their privacy settings and manage the information shared within the app`,
        ans: `Yes, the Good Good Piggy app typically provides users with privacy settings and controls. Users can manage their information sharing preferences, choose what data is shared within the app, and have control over their privacy settings to align with their preferences.Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 5,
        ques: `Does the app provide transparency regarding the types of data collected and how it is used`,
        ans:  ` Yes, the Good Good Piggy app generally provides transparency regarding the types of data collected and how it is used. This information is often detailed in the app's privacy policy or terms of service, which outline the purpose of data collection and the app's data usage practices.Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 6,
        ques: 'What steps are taken to prevent unauthorized access to user data within the app',
        ans: ` The Good Good Piggy app employs various measures to prevent unauthorized access to user data. Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 7,
        ques: `Is user data shared with third parties, and if so, what are the purposes of such sharing`,
        ans: ` The sharing of user data with third parties, if any, depends on the app's policies and practices. Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 8,
        ques: `How long is user data retained within the Good Good Piggy app`,
        ans: ` The duration for which user data is retained within the Good Good Piggy app may vary based on the app's data retention policies and legal requirements. Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 9,
        ques: `Are there options for users to delete or request the deletion of their data from the app`,
        ans: ` Yes, the Good Good Piggy app usually offers options for users to delete or request the deletion of their data. Users (parents only) can typically find this option in their account settings or by contacting the app's customer support on support@goodgoodpiggy.com to initiate the data deletion process.Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
    {
        id: 10,
        ques: `How does the app handle data breaches and notify users in the event of a security incident`,
        ans: `In the event of a data breach or security incident, the Good Good Piggy app follows established protocols to mitigate the impact and protect user data. Read more on - https://www.goodgoodpiggy.com/privacy-policy`
    },
]

export const autoApprove = [
    {
        id: 1,
        ques: 'How can I access the Auto Approve feature in the Good Good Piggy app',
        ans: `To access the auto-approve feature, follow these steps:

        Go to the parent dashboard.
        Locate the settings option.
        Within the settings menu, find and select the "Auto Approval" option.
        In the auto-approval settings, you can configure it based on your specific needs and preferences.`
    },
    {
        id: 2,
        ques: 'How do I set a monthly expense limit in the Auto Approve feature',
        ans: `In the Auto Approve feature, you can usually set a monthly expense limit by entering the desired amount within the app's interface. This amount represents the maximum spending threshold for automatic approval of payments.`
    },
    {
        id: 3,
        ques: `Can I customize the duration of the monthly period for Auto Approve`,
        ans: `Apologies for the confusion. While you cannot customize the monthly duration further within the auto-approve feature, you do have the flexibility to adjust the amount whenever you want. This means you can modify the approval threshold amount as needed, regardless of the monthly duration. Just for you to note Monthy payments are made on 1st and weekly are done on Mondays!`
    },
    {
        id: 4,
        ques: `What happens if a payment exceeds the specified amount in Auto Approve`,
        ans: `If a payment exceeds the specified amount in Auto Approve, it typically requires manual approval from the user. Auto Approve is designed to automatically approve payments below the set threshold, ensuring a convenient and streamlined experience for smaller transactions.`
    },
    {
        id: 5,
        ques: `Can I adjust or change the expense limit during the monthly period`,
        ans:  `Certainly! You can adjust the amount for the auto-approve feature by accessing the settings related to auto-approval. Within this feature, you have the option to set the specific amount under the selection based on a monthly basis. This allows you to customize and configure the auto-approval threshold according to your preferences and requirements.`
    },
    {
        id: 6,
        ques: 'Is there a notification or alert system for Auto Approve transactions',
        ans: `Yes, the Auto Approve feature often includes a notification or alert system to keep users informed about transactions. This can include notifications for approved payments, notifications for payments requiring manual approval, or alerts when the expense limit is nearing or exceeded.`
    },
]

export const connectHub = [
    {
        id: 1,
        ques: 'How can I access the Connect Hub feature in the Good Good Piggy app',
        ans: `Connect Hub is not a mobile feature in the app. It is a mailing and whatsapp system where Good Good Piggy events are pushed to you on email and whatsapp. As a app user, you will always find the latest from us on your fingertips!`
    },
    {
        id: 2,
        ques: 'What kind of events and activities are typically featured in the Connect Hub',
        ans: `The Connect Hub typically features a variety of events and activities for kids, which can include educational workshops, fun outings, interactive sessions, skill-building classes, or community-based initiatives. The events aim to provide engaging and enriching experiences for parents and kids.`
    },
    {
        id: 3,
        ques: `Is participation in the events organized through the Connect Hub free or does it require additional fees`,
        ans: `The participation in events organized through the Connect Hub can vary. Some events may be free of charge, while others may require payment or ticket purchase. The details of event participation, including any associated fees, are usually provided within the event information.`
    },
    {
        id: 4,
        ques: `Can I RSVP or register for events directly through the Connect Hub`,
        ans: `Yes, the Connect Hub often provides options to RSVP or register for events directly through the app. You can typically find buttons or links within the event details that allow you to indicate your interest or secure a spot for the event.`
    },
    {
        id: 5,
        ques: `Are the events hosted by Good Good Piggy or are they organized by external partners`,
        ans:  `The events featured in the Connect Hub can be hosted by Good Good Piggy or organized in partnership with external partners. The app usually provides clear information about the event organizers or partners involved in each event.`
    },
    {
        id: 6,
        ques: 'How often are new events added to the Connect Hub',
        ans: `The frequency of new events being added to the Connect Hub can vary. It depends on the event schedule and the initiatives of Good Good Piggy to offer diverse and engaging experiences for kids. New events may be added periodically, such as weekly, monthly, or based on specific occasions.`
    },
    {
        id: 7,
        ques: `Can I connect and communicate with other parents or caregivers through the Connect Hub`,
        ans:  `Yes, the Connect Hub often provides offline and online opportunities for parents or caregivers to connect and communicate with each other. We are working on features like discussion boards, chat functionalities, or dedicated community forums within the app.`
    },
    {
        id: 8,
        ques: 'What measures are taken to ensure the safety and security of participants in the events organized through the Connect Hub',
        ans: `Good Good Piggy takes measures to ensure the safety and security of participants in the events organized through the Connect Hub. This can include thorough vetting and selection of event partners, implementing safety guidelines and protocols for events, and providing clear information about safety measures to participants. Good Good Piggy may also encourage feedback and maintain open channels of communication to address any concerns related to event safety and security.`
    },
]