import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 0);
  }, []);

  return <div>NotFound</div>;
};

export default NotFound;
