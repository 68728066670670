import React, { useEffect, useRef } from "react";
import AboutImg from "../assets/images/driver.png";
import { Link } from "react-router-dom";
import { AiFillCar } from "react-icons/ai";
import { GiSteeringWheel } from "react-icons/gi";
import { SiTransportforlondon } from "react-icons/si";
import { FaWarehouse } from "react-icons/fa";
import AOS from "aos";
import Title from "./Title/Title";
import one from "../assets/images/ggp/about/1.svg";
import two from "../assets/images/ggp/about/2.svg";
import three from "../assets/images/ggp/about/3.png";
import four from "../assets/images/ggp/about/4.svg";
import five from "../assets/images/ggp/about/5.svg";
import finance from "../assets/images/ggp/about/finance.png";
import mindset from "../assets/images/ggp/about/mindset.png";
import app from "../assets/images/ggp/about/app.png";

const About = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);
  return (
    <>
      <section className="about_wrapper pt-1">
        <div className="container py-5">
          <Title
            title="Why Choose Good Good Piggy as Your Kid's Money-Management Mentor?
"
          />
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-sm-12 text-md-start">
              <div className="row d-flex justify-content-center">
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="#">
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={one}
                      alt=""
                    />
                    <h2>Safe and Controlled Environment</h2>
                  </div>
                  <p className="m-0">
                    Good Good Piggy provides a secure online platform where kids
                    can learn about money within parent-defined boundaries,
                    ensuring age-appropriate activities and informed decisions.
                  </p>
                </Link>
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="#">
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={two}
                      alt=""
                    />
                    <h2>Comprehensive Learning</h2>
                  </div>
                  <p className="m-0">
                    Beyond the Basics: Good Good Piggy covers essential money
                    concepts like budgeting, goal setting, and investing,
                    equipping kids with valuable financial skills for their
                    future.
                  </p>
                </Link>
              </div>
              <div className="row d-flex justify-content-center">
                <Link
                  className="col-lg-5 col-md-12 col-11 service-box"
                  to="#"
                  data-aos="zoom-in"
                  data-aos-duration="700"
                >
                  
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={mindset}
                      alt=""
                    />
                    <h2>Encourages Thoughtful Decision-Making</h2>
                  </div>
                  <p className="m-0">
                    Good Good Piggy fosters critical thinking about money,
                    helping children differentiate between needs and wants,
                    leading to smarter spending choices.
                  </p>
                </Link>
                <Link
                  className="col-lg-5 col-md-12 col-11 service-box"
                  to="#"
                  data-aos="zoom-in"
                  data-aos-duration="700"
                >
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={app}
                      alt=""
                    />
                    <h2>Fun and Interactive Experience</h2>
                  </div>
                  <p className="m-0">
                    Good Good Piggy engages kids with entertaining gamification
                    elements, using kid-friendly language and visuals for an
                    enjoyable learning journey.
                  </p>
                </Link>
              </div>
              <div className="row d-flex justify-content-center">
                <Link
                  className="col-lg-5 col-md-12 col-11 service-box"
                  to="#"
                  data-aos="zoom-in"
                  data-aos-duration="700"
                >
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={finance}
                      alt=""
                    />
                    <h2>Promoting a Positive Financial Mindset</h2>
                  </div>
                  <p className="m-0">
                    Good Good Piggy emphasises parent involvement, enabling
                    progress monitoring, goal setting, and meaningful money
                    discussions, strengthening family bonds and instilling a
                    positive financial outlook.
                  </p>
                </Link>
                <Link
                  className="col-lg-5 col-md-12 col-11 service-box"
                  to="#"
                  data-aos="zoom-in"
                  data-aos-duration="700"
                >
                  <div className="about-card-heading">
                    <img
                      className="img-fluid about-card-img"
                      src={three}
                      alt=""
                    />
                    <h2>Responsible Consumerism</h2>
                  </div>
                  <p className="m-0">
                    Good Good Piggy shop teaches kids to make wise choices with
                    their money, allocating funds wisely between needs and
                    wants, nurturing a sense of responsible consumer behaviour.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
