import b1 from "../images/books/b1.png"
import b2 from "../images/books/b2.png"
import b3 from "../images/books/b3.png"
import b4 from "../images/books/b4.png"
import b5 from "../images/books/b5.png"
import b6 from "../images/books/b6.png"
import b7 from "../images/books/b7.png"
import b8 from "../images/books/b8.png"
import b9 from "../images/books/b9.png"
import b10 from "../images/books/b10.png"



export const book = [
    {
        id:1,
        img:b1,
        name: "Good Good Piggy Learns to Save",
        kindle:"https://www.amazon.in/Piggy-Learns-Money-Lessons-Learners-ebook/dp/B0C7L8SYPZ/ref=sr_1_42?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-42",
        npress:"https://notionpress.com/read/good-good-piggy-learns-to-save",
    },
    {
        id:2,
        img:b2,
        name: "Good Good Piggy Learns to Count",
        kindle:"https://www.amazon.in/Piggy-Learns-Count-Lessons-Learners-ebook/dp/B0C7HNCGCL/ref=sr_1_1?crid=35X7H8969FPJR&keywords=good+good+piggy+learns+to+count&qid=1687267004&sprefix=good+good+piggy+learns+to+cou%2Caps%2C211&sr=8-1",
        npress:"https://notionpress.com/read/good-good-piggy-learns-to-count",
    },
    {
        id:3,
        img:b3,
        name: "Good Good Piggy Grows Wise",
        kindle:"https://www.amazon.in/Piggy-Grows-Money-Lessons-Learners-ebook/dp/B0C823S6RZ/ref=sr_1_39?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-39",
        npress:"https://notionpress.com/read/good-good-piggy-grows-wise",
    },
    {
        id:4,
        img:b4,
        name: "Good Good Piggy Learns to Value Money",
        kindle:"https://www.amazon.in/Piggy-Learns-Value-Lessons-Learners-ebook/dp/B0C8261M31/ref=sr_1_6?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-6",
        npress:"https://notionpress.com/read/good-good-piggy-learns-to-value-money",
    },
    {
        id:5,
        img:b5,
        name: "Good Good Piggy Learns about Kindness",
        kindle:"https://www.amazon.in/Piggy-Learns-Kindness-Lessons-Learners-ebook/dp/B0C821G128/ref=sr_1_14?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-14",
        npress:"https://notionpress.com/read/good-good-piggy-learns-about-kindness",
    },

    {
        id:6,
        img:b6,
        name: "Good Good Piggy Explores Payments",
        kindle:"https://www.amazon.in/Piggy-Explores-Payments-Lessons-Learners-ebook/dp/B0C825NCND/ref=sr_1_10?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-10",
        npress:"https://notionpress.com/read/good-good-piggy-explores-payments",
    },
    
    {
        id:7,
        img:b7,
        name: "Good Good Piggy Learns to Earn",
        kindle:"https://www.amazon.in/Piggy-Learns-Money-Lessons-Learners-ebook/dp/B0C821873R/ref=sr_1_13?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-13",
        npress:"https://notionpress.com/read/good-good-piggy-learns-to-earn",
    },
    {
        id:8,
        img:b8,
        name: "Good Good Piggy Makes Smart Choices",
        kindle:"https://www.amazon.in/Piggy-Empowers-Choices-Lessons-Learners-ebook/dp/B0C823XKT8/ref=sr_1_11?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-11",
        npress:"https://notionpress.com/read/good-good-piggy-makes-smart-choices",
    },
    {
        id:9,
        img:b9,
        name: "Good Good Piggy Becomes Money Wise",
        kindle:"https://www.amazon.in/Piggy-Becomes-Money-Lessons-Learners-ebook/dp/B0C823HS4B/ref=sr_1_12?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-12",
        npress:"https://notionpress.com/read/good-good-piggy-becomes-money-wise",
    },
    {
        id:10,
        img:b10,
        name: "Good Good Piggy Explores Entrepreneurship",
        kindle:"https://www.amazon.in/Piggy-Explores-Entrepreneurship-Lessons-Learners-ebook/dp/B0C8236XGZ/ref=sr_1_15?crid=2RELO0RRP3DKJ&keywords=good+good+piggy&qid=1687266794&sprefix=good+good+pigg%2Caps%2C332&sr=8-15",
        npress:"https://notionpress.com/read/good-good-piggy-explores-entrepreneurship",
    },
    
    

]