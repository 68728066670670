import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaLinkedin, FaInstagram } from 'react-icons/fa'
import {GrYoutube} from 'react-icons/gr'
const Topbar = () => {
  return (
      <>
  <section className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="topbar-content">
                <ul className="contact__list list-unstyled mb-0">
                  <li>
                    <FaEnvelope className="topbar-icon"/>
                    <a href="mailto:hello@goodgoodpiggy.com">
                    hello@goodgoodpiggy.com
                    </a>
                  </li>
                  <li>
                    <FaPhoneAlt className="topbar-icon"/>
                    <a href="tel:919144792447">+91 9144792447</a>
                  </li>
                </ul>
                <div className="social-container">
                  <ul className="social-icons list-unstyled mb-0 mr-20">
                    <li>
                      <a href="https://www.youtube.com/@goodgoodpiggy" target="blank">
                        <GrYoutube />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/goodgoodpiggy.app/?hl=en" target="blank">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/good-good-piggy/" target="blank">
                        <FaLinkedin />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
  );
};

export default Topbar;
