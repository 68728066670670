import React from "react";
import Title from "./Title/Title";
import Para from "./Title/Para";

const Youtube = () => {
  return (
    <>
      <section className="youtube-section py-5">
        <div className="container">
          <Title title="Welcome to Good Good Piggy" />
          <Para para="Introducing Good Good Piggy! This groundbreaking digital piggy bank simplifies teaching kids about personal finance, enabling you to instill vital financial wisdom in them. Empower your children with the necessary knowledge to make responsible and informed financial choices as they grow into responsible adults." />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 col-md-4">
              <iframe
                width="85%"
                height="400"
                src="https://www.youtube.com/embed/lkp5WNQm5nY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

     
    </>
  );
};

export default Youtube;
