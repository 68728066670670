import React from "react";

const Terms = () => {
  return (
    <>
      <div align="justify" style={{ margin: "5% 7.5% ", width: "85%" }}>
        <h3 style={{ fontWeight: "normal" }}> Terms and Conditions of Use</h3>
        <p>
          The "Good Good Piggy" app (hereinafter referred to as the "GGP App")
          for the Google Play Store is owned and operated by FinKids
          Innovations Private Limited, having its registered office at: 172,
          3rd Floor, Kailash Hills, New Delhi - 110065 (India). Please read the
          Conditions of Use document carefully before using GGP App. By
          using/accessing/registering for/downloading the GGP APP or otherwise
          using our Services, you are agreeing to all of the following terms and
          conditions, including any policies referred to herein (collectively,
          these "Terms"). So, please read these Terms carefully. We reserve the
          right to change the GGP App and these Terms at any time. If you are
          unwilling to be bound by these Terms, you should not browse, access,
          use, register for or download the GPP App. You represent and warrant
          that you are at least 18 years old or using the GGP App under the
          supervision of a parent or guardian.
        </p>

        <br />
        <br />
        <h5>Privacy Policy</h5>
        <p>
          Our Privacy Policy, which also governs your use of the GGP App. Please
          review our Privacy Policy for information on how we collect, use and
          share information about our users.
        </p>

        <br />

        <h5>Use of the GGP App</h5>
        <p>
          Subject to your compliance with these Terms, we grant you a limited,
          non-exclusive, non-transferable, non-sublicensable licence to access
          and make personal, non-commercial use of the GGP App subject to you
          registering on the App and subscribing to the Services offered on it.
          <br />
          <br />
          Subscription model and users: We provide our services on a freemium
          model on the GGP App, where the users over the age of 18 subscribe to
          the model (hereinafter referred to as the “Principal User/Users”) and
          nominate their child/ward/relative under the age of 18 to become
          benefactors of the “beneficiary services” provided for hereinafter
          (hereinafter referred to as the “Underage Benefactor/Benefactors”).
          The user over the age of 18, who freely use the model and purchase in
          app subscriptions as part of freemium model, will be solely
          responsible for all acts and omissions made by the underage
          benefactors on the GGP App.
        </p>
        <br />
        <br />

        <h5 style={{ fontWeight: "normal" }}>
          <u>Digital Wallet:</u>
        </h5>
        <p>
          The Underage Benefactors will be assigned a digital wallet wherein
          money can be transferred/uploaded by the Principal User through direct
          transfer or through the in-app Rewards Service. The control over the
          use/withdrawal/transfer/deposit of money to the Digital Wallet of the
          Underage Benefactor shall be in the Control of the Principal User.
          Other than the Principal User, the Underage Benefactor can also
          receive money from other people (such as relatives) in his Digital
          Wallet by way of direct transfers. The Underage Benefactor may raise
          requests for the use/withdrawal/transfer/deposit of money to and from
          their Digital Wallet on the GGP App but the same shall be subject to
          approval by the Principal User on their version of the App. Once such
          a request is approved by the Principal User's Account, the Principal
          User shall solely be responsible for all and any outcomes of such
          use/withdrawal/transfer/deposit of money.
          <br />
          <br />
          The Digital Wallet will be subject to the following terms:
          <br />
          <br />
          1.No interest will be payable to you on the balance reflected in the
          Wallet.
          <br />
          <br />
          2.Wallets are not transferable.
          <br />
          <br />
          3.We can suspend/discontinue the Digital Wallets issued to you or the
          Underage Beneficiary at any time, for any cause, including but not
          limited, to the following:
          <br />
          <br />
          For any suspected violation of RBI Regulations/Applicable Law;
          <br />
          <br />
          (b)For any violation of these Terms or the Privacy Policy;
          <br />
          <br />
          (c)To combat potential fraud, sabotage, wilful destruction, threat to
          national security or for any other force majeure event;
          <br />
          <br />
          (d)On account of technical failure, modification, upgradation,
          variation, relocation, repair, and/or maintenance due to any emergency
          or for any technical reasons;
          <br />
          <br />
          (e)On account of any transmission deficiencies;
          <br />
          <br />
          (f)If the mobile connection with which your Wallet is related is
          un-operational;
          <br />
          <br />
          (g)If we believe that cessation/suspension is necessary for any other
          legitimate purpose.
        </p>
        <br />
        <br />

        <h5 style={{ fontWeight: "normal" }}>
          <u>User Services:</u>
        </h5>
        <p>
          The Principal Users, who subscribe to the services provided by the GGP
          App for their respective benefactors shall have ability to
          use/withdrawal/transfer/deposit of money to the Digital Wallet of the
          Underage Benefactor, set up custom rewards for their respective
          Underage Benefactors and avail all other services designed
          specifically for the Principal User from time to time.
        </p>
        <br />
        <br />

        <h5 style={{ fontWeight: "normal" }}>
          <u>Benefactor Services:</u>{" "}
        </h5>
        <p>
          The Underage Benefactors shall be assigned a digital wallet wherein
          money can be transferred/uploaded by the Principal User through direct
          transfer or through the in-app Rewards Service, raise requests for the
          use/withdrawal/transfer/deposit of money to the Principal Users, reach
          the goals set up by the Principal Users to earn rewards and avail all
          other services designed specifically for the underage Benefactors from
          time to time.
        </p>

        <br />
        <br />

        <h5 style={{ fontWeight: "normal" }}>
          <u>GGP Store:</u>{" "}
        </h5>
        <p>
          The Good Good Piggy Store (hereinafter referred to as the "GGP Store")
          on the GGP App is an e-commerce platform that acts as an online
          marketplace that facilitates the sale of products from curated
          catalogue of products. The products sold on the GGP Store on the GGP
          App are neither manufactured nor distributed by FinKids Innovations
          Private Limited and assumes no responsibility for the quality of the
          products.
        </p>

        <br />
        <br />

        <h5>Shipping and Delivery of products purchased from the GGP Store </h5>
        <p>
          Orders placed through the GGP Store on the GGP App shall be shipped
          within a reasonable time period. The delivery periods as well as
          delivery charges to the Principal User's address for every product
          shall be mentioned on the product description page. The shipping and
          delivery of products purchased on the GGP App shall be undertaken by
          the product vendor. and their Terms and conditions for shipping and
          delivery shall apply. The General Terms with respect to shipping and
          delivery that shall apply to all orders placed on the GGP Store are as
          follows:
          <br />
          <br />
          <b>•</b>The shipping timelines provided on the GGP Store are not
          absolute and the products may be delayed on account inventory and
          courier related issues.
          <br />
          <br />
          <b>•</b>Orders placed on the GGP Store are processed within 7 business
          days from the time the payment for the order is verified.
          <br />
          <br />
          <b>•</b>We ship all across India.
        </p>

        <h3>Referral Code Policy </h3>
        <p>
          Welcome to GoodGoodPiggy.com! We're excited to have you as part of our
          community. To enhance your experience and encourage the growth of our
          platform, we've implemented a referral program. Please take a moment
          to review the terms and conditions outlined below:{" "}
        </p>

        <h5>Eligibility: </h5>
        <p>
          To participate in the referral program, users must have an active
          account on GoodGoodPiggy.com.
          <br />
          Referrers must share unique referral codes provided by Good Good Piggy
          with potential new users.{" "}
        </p>

        <h5> Earning Referral Rewards:</h5>
        <p>
          Referrers will earn 50 Rupees for each successful referral.
          <br />A successful referral is defined as a new user signup or
          purchase using the unique referral code.{" "}
        </p>

        <h5>Sharing Referral Codes: </h5>
        <p>
          Referral codes are intended for personal use only and should not be
          published or shared on coupon websites or similar platforms. Users
          found abusing the referral system may be subject to account suspension
          or termination.{" "}
        </p>

        <h5>Redemption of Referral Rewards: </h5>
        <p>
          Referral rewards of 50 Rupees will be added to the user's wallet.{" "}
          <br />
          Rewards are subject to change at Good Good Piggy's discretion.
        </p>

        <h5>Tracking Referrals: </h5>
        <p>
          Referral activities and rewards can be tracked in the user's account
          dashboard. <br />
          Good Good Piggy reserves the right to adjust or deny referral rewards
          in the case of fraudulent or suspicious activities.
        </p>

        <h5>Modification and Termination:</h5>
        <p>
          Good Good Piggy reserves the right to modify or terminate the referral
          program at any time. Notice of changes will be communicated to users
          through the GoodGoodPiggy.com platform.
        </p>

        <h5>Miscellaneous</h5>
        <p>
          Referral codes have no cash value and cannot be transferred or
          exchanged. Users are responsible for ensuring that their referrals use
          the provided code during the signup or purchase process.{" "}
        </p>

        <p>
          By participating in the referral program, users agree to abide by the
          terms and conditions outlined in this policy. Good Good Piggy reserves
          the right to take appropriate action against users who violate these
          terms.{" "}
        </p>

        <p>
          If you have any questions or concerns regarding the referral program,
          please contact our support team at Support@GoodGoodPiggy.com.{" "}
        </p>

        <br />
        <br />

        <h5>Legal responsibility on behalf of Underage Benefactors</h5>
        <p>
          Users below the age of 18 years i.e. Underage Benefactors can use the
          App only with the permission and under the supervision of their parent
          or legally appointed guardian i.e. Principal User. As the Principal
          User you must agree to and accept these Terms and the Privacy Policy
          on behalf of the Underage Benefactors. If you agree to these Terms and
          the Privacy Policy on behalf of an Underage Benefactor, you represent
          to us that you are their parent/Lawful Guardian. As a Parent/Lawful
          Guardian, you agree to take full financial and legal responsibility
          for the acts and omissions of the Underage Benefactors. An Underage
          Benefactors' use of the Services and interaction with the App must be
          supervised by the Parent/Lawful Guardian. As a Parent/Lawful Guardian
          you must take full responsibility for Underage Benefactors while using
          the Services and any interaction with the GGP App. By taking
          responsibility for a Minor, you authorize us to make inquiries (if we
          choose to do so), either directly or through third parties to validate
          the information provided about the Minor or your relationship with the
          Minor. This could include information to verify your relationship as a
          Parent/Lawful Guardian to the Minor(s).
        </p>

        <br />
        <br />
        <h5>Restrictions on Rights to Use </h5>
        <p>
          You agree you shall not (and you agree not to allow any other
          individual or entity using your password and identification to):
          <br />
          <br />
          download, modify, reproduce, adapt, translate, reverse engineer,
          create derivative works based upon, publicly display, sell, rent,
          license, or in any way commercially exploit any portion of this GGP
          App;
          <br />
          <br />
          <b>•</b>•remove any copyright, trademark or other proprietary rights
          notice contained in or on the GGP App;
          <br />
          <br />
          <b>•</b>use any robot, spider, site search/retrieval application, or
          other device to retrieve or index any portion of the GGP App;
          <br />
          <br />
          <b>•</b>collect any information about other users (including usernames
          and/or email addresses) for any purpose;
          <br />
          <br />
          <b>•</b>create user accounts by automated means or under false or
          fraudulent pretences;
          <br />
          <br />
          <b>•</b>create or transmit to other users unsolicited electronic
          communications, such as "spam," or otherwise interfere with other
          users' enjoyment of the App;
          <br />
          <br />
          <b>•</b>use the App to violate the security of or gain unauthorised
          access to any computer or computer network or other device or system
          (including unauthorised attempts to discover passwords or security
          encryption codes);
          <br />
          <br />
          submit any content that is unlawful or facilitates, constitutes,
          promotes or encourages illegal activity; or otherwise use the App to
          transfer or store illegal material, including any material deemed
          threatening or obscene;
          <br />
          <br />
          <b>•</b>take any action that imposes, or may impose, in our sole
          discretion, an unreasonable or disproportionately large data or
          traffic load on the App or the IT infrastructure used to operate and
          make the App available; or
          <br />
          <br />
          <b>•</b>use this App and/ or any Content, intentionally or
          unintentionally, to violate any applicable local, state, federal or
          international law. We have no obligation to monitor any user conduct
          on the App, and we reserve the right and have absolute discretion to
          monitor any user conduct at any time and for any reason without
          notice.
        </p>

        <br />
        <br />

        <h5>Accounts and Registration</h5>
        <p>
          To use the services provided by us you will have to sign up and
          register on the GPP App or on the "Good Good Piggy" Web App and we may
          assign to you, or you may be required to select a password and user
          name or account identification. If you register, you agree to provide
          us with accurate and complete registration information, and to inform
          us immediately of any updates or other changes to such information. At
          the time of registering your account you will be asked to provide a
          mobile number being used by you, which will be subject to
          authentication through OTP (One Time Password).
          <br />
          <br />
          You are solely responsible for protecting the security and
          confidentiality of the password and identification assigned to you.
          You shall immediately notify us of any unauthorised use of your
          password or identification or any other breach or threatened breach of
          this App's security. Each time you use a password or identification,
          you will be deemed to be authorised to access and use the App in a
          manner consistent with these Terms, and we have no obligation to
          investigate the authorization or source of any such access or use of
          this App.
          <br />
          <br />
          YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THIS GGP
          APP BY ANYONE USING THE PASSWORD AND IDENTIFICATION ORIGINALLY
          SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH ACCESS TO AND USE
          OF THIS APP IS ACTUALLY AUTHORISED BY YOU, INCLUDING WITHOUT
          LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL OBLIGATIONS
          (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS) INCURRED THROUGH
          SUCH ACCESS OR USE.
        </p>

        <br />
        <br />

        <h5>Electronic Communication </h5>
        <p>
          When you use the GGP App, or send emails to us, you are communicating
          with us electronically. You consent to receive communications from us
          electronically. We will communicate with you by e-mail or by posting
          notices on the App or through our other services. You agree that all
          agreements, notices, disclosures and other communication that we
          provide to you electronically satisfy any legal requirements that such
          communications be in writing.
        </p>

        <br />
        <br />

        <h5>User's Obligations</h5>
        <p>
          You agree to not interfere with or use non-public areas of the GGP App
          and our technical delivery system. You will not introduce any trojans,
          viruses, any other malicious software, any bots or scrape our Platform
          for any user information. Additionally, you will not probe, scan, or
          test the vulnerability of any system, security or authentication
          measures implemented by us. If you tamper or attempt to tamper with
          our technological design and architecture, we may terminate your
          Account. We may further report such actions to the appropriate law
          enforcement authorities and initiate legal action.
          <br />
          <br />
          You shall use the Services only for your lawful and personal use and
          shall not use the App or the Services for committing fraud,
          embezzlement, money laundering or for any other unlawful and/or
          illegal purposes. The information you provide is used by us to
          determine your eligibility and continuing use of your Wallet. It is
          critical that all information you provide to us is true, complete, not
          misleading and is regularly updated by you. If all or any part of this
          information is incorrect, incomplete or misleading, it would be a
          breach of these Terms and a violation of the law. We may approach
          appropriate authorities to initiate legal action against you. You must
          further ensure that you do not do anything that can make the
          information provided by you incorrect, incomplete or misleading at a
          later date.
          <br />
          <br />
          Considering the nature of the Platform and Services provided, please
          ensure that you keep your mobile device safe. You are solely
          responsible for all activities that occur under your credentials on
          the GGP App and for any amounts debited from or credited to your
          Wallet. You should keep your password safe and not disclose your
          Account details to any third party or share your Account with any
          third party. You must ensure that you keep updating the GGP App as and
          when we release new versions of it. Failure to do so may make you
          incapable of using the Platform and our Services.
        </p>

        <h5>User Account Deletion/Break Gullak</h5>

        <p>
          The GGP App provides its Users with a feature of User Account Deletion
          which may be worded as ‘‘Break the Gullak’ upon company discretion;
          which enables the Principal Users to withdraw all monies as available
          in the Digital Wallet from the GGP App and thereby close their account
          on the GGP App.
        </p>
        <p>
          The feature permits Principal Users thereby encouraging their children
          to save money (for every task completed by the child) by setting the
          timeline anywhere between 1 (one) year to 4 (four) years, after which
          the Digital Wallet would become eligible to break and gain rewards.
          The Principal User acknowledges that he/she shall have the discretion
          to set a time frame with regards breaking the gullak in accordance
          with their preference.
        </p>
        <p>
          (a) Upon initiating a withdrawal request through the GGP App, the
          withdrawal amount shall be redeemed to the Principal User at the
          discretion of the company in the form of rewards/coupons/cashback in
          accordance with the applicable laws and regulations.
        </p>
        <p>OR</p>
        <p>
          Upon initiating the withdrawal from the Digital Wallet, the transfer
          of funds shall take up to 10 business days from the date of such
          request to the source account of the Principal User (“Primary Source
          Account”). Alternatively, the Principal User may also designate
          another account (“Designated Source Account”) as the source account to
          which the Principal User is desirous of transferring the money to,
          however, GGP shall be entitled to carry out an additional KYC
          verification in order to transfer money to the Designated Source
          Account through the GGP App and the timeframe for transfer of funds
          may depend on completion of such KYC verification. Notwithstanding
          anything contained herein, GGP reserves the right to deny transfer of
          funds to any Designate Source Account in the event the Designated
          Source Account is not KYC compliant.
        </p>
        <p>
          (b) In the event the Principal User uses the feature after expiry of
          said period from the date of accessing the Digital Wallet on the GGP
          App, the Principal User shall be entitled to % worth of rewards
          mentioned in app (including but not limited to vouchers, merchandise,
          coupons etc.) over and above the rewards (equivalent to the withdrawal
          amount) received by the Principal User, that can be availed through
          the GGP App.
        </p>
        <p>
          (c) The Principal User agrees and the acknowledges that the % rewards
          are an added benefit. In the event of unsuccessful transfer of the
          rewards due to any security breaches or cyber-attacks on the GGP App,
          beyond our control, the Principal User agrees to waive any objection
          which it may have now or hereafter in relation to the GGP App and we
          shall not be liable due to any delay or non-performance in performing
          such function as enumerated herein.
        </p>
        <p>
          (d) Any data collected from the Principal User, through the GGP App
          will be utilised in accordance with the Applicable Laws. The data
          collected shall be stored on a need- to know basis for a period as may
          be prescribed under the relevant laws. However, upon breaking the
          gullak/account deletion, the Principal User shall also have the option
          of withdrawing all data as shared through the GGP App. In the event
          the Principal User does not intimate their wish to withdraw their data
          through the GGP App or opts for allowing data retention by the GGP
          App, then the GGP App reserves the right to utilise such data in
          accordance with the applicable laws.
        </p>
        <p>
          (e) Once a withdrawal request has been placed on the GGP App, a
          confirmation email shall be shared with the Principal User confirming
          the closure of the account on the GGP App. (f) Upon breaking the
          gullak, the Principal User shall have the option to continue with its
          user account or delete the account permanently.
        </p>
        <p>
          (g) The GGP App reserves the right to deduct tax as may be applicable
          at the time of withdrawal of funds from the Digital Wallet, as per the
          relevant laws.
        </p>

        <br />
        <br />

        <h5>Returns, Cancellations and Refunds </h5>
        <p>
          Returns and Refund of the products bought from the GGP Store on the
          GGP App: Good Good Piggy offers flexible returns and exchange policy.
          If the Principal User is not satisfied, the Principal User can
          return/exchange the product by writing an email to
          support@goodgoodpiggy.com in this regard stating the intent to return
          or get the product exchanged along with the specific reason for
          returning the product delivered. However, there are a few basic rules
          to be eligible for returning the product:
          <br />
          <br />
          <b>•</b>Product has to be in original and unused condition.
          <br />
          <br />
          <b>•</b>The request for return/exchange has to be raised within 48
          hours from the time of delivery.
          <br />
          <br />
          <b>•</b>We accept the request if there is a mismatch in quality,
          quantity, size, colour or design or in case an item is missing/wrong.
          <br />
          <br />
          <b>•</b>Hand over the correct product, as per the AWB and Order ID.
          <br />
          <br />
          <b>•</b>The Principal User shall receive a proof of return at the time
          of pickup of the returned products and the Principal User shall retain
          the same for record.
          <br />
          <br />
          <b>•</b>The Principal User will be responsible to pay the return
          pickup charges.
          <br />
          <br />
          Once the return/exchange request for the products is accepted, in case
          the Principal User has made an exchange request, a replacement product
          shall be shipped to the Principal User on his given address and in
          case the Principal User has requested for a refund, the amount paid by
          the Principal User shall be refunded back to the source within 30 days
          from the date on which such request is accepted.
          <br />
          <br />
          <u>Refund and cancellation of subscription to the GGP App:</u>
          <p>
            The The Principal User may at any time during the subscription
            period terminate the subscription to the GGP App by writing an email
            to this effect to support@goodgoodpiggy.com stating their request to
            this effect along with the reasons for such request. In case the
            Principal User is dissatisfied by the services provided by GGP and
            wants a refund for the subscription, in addition to cancelling, the
            Principal User must request for a refund and such request shall
            state the reason why the Principal User is of the opinion that he is
            eligible to receive one. The support team at Good Good Piggy on
            receiving such a request will examine and consider the request made
            by the Principal User and give their finding on whether such request
            warrants a refund to the Principal User. In case the refund is
            approved, the Principal User will be refunded the subscription
            amount back to the source of payment used to make the payment, on a
            pro rata basis. The entire refund process may take up to 30 days to
            complete.
          </p>
          <br />
          <br />
          IT IS SPECIFIED THAT THE SUBSCRIPTION TO THE GGP APP IS A
          NON-REFUNDABLE SUBSCRIPTION, HOWEVER IF THE REQUEST FOR REFUND IS WELL
          REASONED IN THE OPINION OF THE SUPPORT TEAM OF GOOD GOOD PIGGY, GOOD
          GOOD PIGGY MAY REFUND BACK THE SUBSCRIPTION AMOUNT ON A PRO RATA
          BASIS.
        </p>

        <br />
        <br />

        <h5>Disclaimers </h5>
        <p>
          Except as expressly provided, this GGP App, including all its Content,
          and services provided on or in connection with this App are provided
          on an "AS IS" and "WITH ALL FAULTS" basis without representations,
          warranties or conditions of any kind, either express or implied. We do
          not represent or warrant that the GGP App is accurate, complete,
          reliable, current or error-free. We do not represent or warrant that
          this Site or our servers are free of viruses or other harmful
          components.
          <br />
          <br />
          WE DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS AND
          DUTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO
          IMPLIED WARRANTIES, DUTIES OR CONDITIONS.
        </p>

        <br />
        <br />

        <h5>Limited Liability in certain cases </h5>
        <p>
          Your sole and exclusive remedy, and our sole and exclusive liability,
          for any breach of warranty shall be your right to receive a refund for
          the service under Our applicable returns policies.
          <br />
          <br />
          IN NO EVENT SHALL WE OR OUR RELATED PARTIES, BE LIABLE FOR SPECIAL,
          INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS
          OR LOSS OF BUSINESS, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES, NOR SHALL OUR AND OUR RELATED PARTIES' AGGREGATE
          LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
          WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY, ARISING OUT OF
          OR RELATING TO THESE TERMS. THE LIMITATIONS SET FORTH IN THIS
          PARAGRAPH WILL NOT LIMIT OR EXCLUDE OUR OR OUR RELATED PARTIES' GROSS
          NEGLIGENCE, FRAUD, INTENTIONAL, WILLFUL, MALICIOUS OR RECKLESS
          MISCONDUCT.
        </p>

        <br />
        <br />

        <h5>Payments </h5>
        <p>
          All payments, including payment of in-app purchases, or subscription
          fee or deposit of money to the Digital Wallets on the GGP App are
          processed and managed by "FREECHARGE PAYMENT Innovations PRIVATE
          LIMITED” (hereinafter referred to "Freecharge") and Freecharge alone
          is responsible and liable for all navmonte and related consequences.
          We are not responsible and can not be held liable for any
          payment/refund related errors/problems. We do not store your card data
          on our servers. The data is encrypted through the Payment Card
          Industry Data Security Standard (PCI-DSS) when Your purchase
          transaction data is only used as long as necessary to complete your
          purchase transaction. After that is complete, your purchase
          transaction information is not saved.
          <br />
          <br />
          For more insight, you may also want to read terms and conditions of
          Freecharge on https://www.freecharge.in/termsandconditions
        </p>

        <br />
        <br />

        <h5>Force majeure </h5>
        <p>
          Without limiting the foregoing, under no circumstances shall we be
          held liable for any damage or loss due to a deficiency in provision of
          the Services resulting directly or indirectly from acts of nature,
          forces, or causes beyond our reasonable control, including, without
          limitation, internet failures, computer equipment failures,
          telecommunication equipment failures, or any other government
          regulations, floods, storms, electrical failure, civil disturbances,
          riots.
        </p>

        <br />
        <br />

        <h5>Intellectual Property </h5>
        <p>
          The GGP App including all photographs, images, text, graphics, icons,
          audio clips, software, source code and other aspects thereof
          (excluding User Content), all improvements or modifications thereof,
          all derivative works based thereon, and the collection, arrangement,
          and assembly of this App (collectively, the "App Content"), including
          all copyrights, trademarks, and other intellectual property or
          proprietary rights in the foregoing, are owned by us or our licensors
          and protected by applicable copyright laws.
          <br />
          <br />
          These Terms Platform, or the Services shall be subject to the
          jurisdiction of the courts located at New Delhi. The use of any of our
          trademarks or service marks without our express written consent is
          strictly prohibited. You may not use our trademarks or service marks
          in connection with any product or service in any way that is likely to
          cause confusion. You may not use our trademarks or service marks in
          any manner that disparages or discredits us. You may not use any of
          our trademarks or service marks in meta tags without prior explicit
          consent. Nothing in these Terms shall be deemed to grant to you or any
          other user any license or right in or to any of Our patents,
          copyrights, trademarks, trade secrets or other proprietary rights.
        </p>

        <br />
        <br />

        <h5>Dispute Resolution </h5>
        <p>
          Any and all Disputes shall be referred to and finally resolved by
          arbitration, held in accordance with the provisions of Arbitration and
          Conciliation Act, 1996, including any amendment or modification
          thereto. The arbitral tribunal shall consist of a sole arbitrator
          appointed shall be FinKids Innovations Private Limited. The seat and
          venue of arbitration shall be at New Delhi. The award and decision of
          the arbitrator shall be final and binding on all parties. The language
          of the arbitration proceedings shall be English. Each party shall bear
          its own expenses and costs in relation to the arbitral proceedings,
          unless otherwise stated in the award.
          <br />
          <br />
          These Termsand any action related thereto will be governed by
          Applicable Law. Any disputes arising out of or related to these Terms
          and/or the Platform , or the services shall be subjected to the
          jurisdiction of the courts located at New Delhi.
        </p>

        <br />
        <br />

        <h5>Miscellaneous Terms</h5>
        <p>
          You shall not assign or transfer any right or obligation that has
          accrued to you under these Terms, and any attempt by you to assign or
          transfer and obligations, shall be null and void. We may assign or
          transfer any right or obligations that accrued in our favour, at our
          sole such rights discretion, without any restriction.
          <br />
          <br />
          Unless otherwise stated expressly, any delay or failure in our
          exercising any rights/remedies arising out of these Terms and/or other
          policies available on the Platform, shall not constitute a waiver of
          rights or remedies and no single/partial exercise of any rights or
          remedies, hereunder, shall prevent any further exercise of the
          rights/remedies by us.
          <br />
          <br />
          You acknowledge that your representations, undertakings, and
          warranties and the clauses relating to indemnities, limitation of
          liability, governing law & arbitration shall survive the efflux of
          time and the termination of these Terms. at law or in equity,
          including without limitation damages injunctive relief, attorneys'
          fees and expenses. If any provision of these Terms
          <br />
          <br />
          is held illegal or unenforceable, the validity, legality and
          enforceability of the remaining provisions contained herein shall not
          in any way be affected or impaired thereby. Any such provision held
          invalid, illegal or unenforceable shall be substituted by a provision
          of similar import reflecting the original intent of the parties to the
          extent permissible under Applicable Law. law
          <br />
          <br />
          You understand and agree that we may have to modify the Services as
          well as the App on account of any regulatory changes. In such case, if
          you become incapable of using all or any part of the App or the
          Services, we shall not be liable to you in any manner.
          <br />
          <br />
          You agree that our remedy at for any actual or threatened breach of
          these Terms would be inadequate and that we shall be entitled to
          specific performance or injunctive relief, or both, in addition to any
          damages that we may be legally entitled to recover, together with
          reasonable expenses of any form of dispute resolution, including,
          without limitation, attorneys' fees. No right or remedy of ours shall
          be exclusive of any other, whether at law or in equity, including
          without limitation damages injunctive relief, attorneys' fees and
          expenses.
          <br />
          <br />
          We may revise these Terms, update the App and modify the Services at
          any time to improve your experience on our Platform and on account of
          changes in Applicable Law. So, please check this page regularly to
          take note of any such changes. If you do not agree with any change
          made by us, you have the option to stop using our Services.
        </p>

        <br />
        <br />
      </div>
    </>
  );
};

export default Terms;
