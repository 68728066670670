import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Title from "./Title/Title";

import one from "../assets/images/ggp/brands/1.png";
import two from "../assets/images/ggp/brands/2.png";
import three from "../assets/images/ggp/brands/3.png";
import four from "../assets/images/ggp/brands/4.png";
import five from "../assets/images/ggp/brands/5.png";
import six from "../assets/images/ggp/brands/6.png";
import seven from "../assets/images/ggp/brands/7.png";
import eight from "../assets/images/ggp/brands/8.png";
import nine from "../assets/images/ggp/brands/9.png";
import ten from "../assets/images/ggp/brands/10.png";

class BrandLogo extends Component {
  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   autoplay: true,
    //   autoplaySpeed: 3000,
    //   speed: 500,
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   initialSlide: 0,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         infinite: true,
    //       },
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         initialSlide: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //       },
    //     },
    //   ],
    // };

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      // variableWidth:true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="brands">
        <Title title="As showcased in" />

        <div className="container mt-4">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>

              {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={one}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy"
                  />
                </div>
                <div className="row mb-4">
                  <img
                    src={two}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy"
                  />
                </div>
                {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={three}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy"
                  />
                </div>
                {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={four}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy"
                  />
                </div>
                {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={five}
                    style={{height:'30%',width:'30%',margin:'0% 35%'}}
                    className="img-fluid"
                    // height="10"
                    // width="10"
                    loading="lazy"
                  />
                </div>
                <div className="row mb-4">
                  <img
                    src={six}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy" />
                </div>
                {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={seven}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy" />
                </div>
                <div className="row mb-4">
                  <img
                    src={eight}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy" />
                </div>
                <div className="row mb-4">
                  <img
                    src={nine}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy" />
                </div>
                {/* {" "} */}
                <div className="row mb-4">
                  <img
                    src={ten}
                    className="img-fluid"
                    height="100"
                    width="150"
                    loading="lazy" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BrandLogo;
