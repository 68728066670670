
import santosh from '../../assets/images/ggp/testimonialImg/Santosh.jpeg'
import digjay from '../../assets/images/ggp/testimonialImg/digjay.jpeg'
import chandrashekhar from '../../assets/images/ggp/testimonialImg/Chandrashekhar.jpeg'
import pranav from '../../assets/images/ggp/testimonialImg/Pranav.jpeg'
import sonal from '../../assets/images/ggp/testimonialImg/sonal.jpeg'
import Sumit from '../../assets/images/ggp/testimonialImg/Sumit.jpeg'
import Yamika from '../../assets/images/ggp/testimonialImg/Yamika.jpeg'



export const slides = [
    {   
        heading:'Giving necessary tools for parents',
        name: 'Santosh Ankola',
        testimonial: '"In a world when younger kids are showered with an exorbitant amount of toys and gadgets, they lack the basic understanding of working hard for a reward. Good Good piggy not only takes the right step in educating a child about effort required for getting the rewards, while also giving necessary tools for parents. Very excited to see a product like this for parents and children."',
        city: 'Product Head, Flurry Analytics Former Head of Product, TechCrunch',
        image: santosh,
        alt: 'Imageone',
        
    },
    {   
        heading:'Helps parents inculcate important financial skills in kids',
        name: 'Digjay Patel',
        testimonial: '"Good Good Piggy is an exciting product that develops good behavioural habits in kids & explains to them the concept of money. The app’s gamification approach helps parents inculcate important financial skills in kids from a very young age. Good Good Piggy will be the go-to app for parents in the coming years and is well positioned to lead the Ed-Fintech race."',
        city: 'Founder, VCpreneur Ex -Corporate & Investment Banker, JP Morgan',
        image: digjay,
        alt: 'Imageone',
        
    },
    {   
        heading:'Teaches Financial Responsibility Early On',
        name: 'Chandrashekar Kupperi',
        testimonial: '"As a father of twin sons, I can say with authority that raising kids is no easy task in the current generation! Though children are tech savvy, they are more hooked on their gadgets, and not really learning certain principles that lead to their better future. Hence my fascination for Good Good Piggy which is helping in teaching financial responsibility early on, so it really makes a difference throughout their life. It is an easy-to-use tool which slowly instils the values and diligence needed in an engaging way."',
        city: 'Founder, ANOVA Corporate Services Limited Partner, PointOne Capital Investor, IvyCap Angel Fund & Inflection Point Ventures',
        image: chandrashekhar,
        alt: 'Imageone',
        
    },
    // {   
    //     heading:'Helps shape a more fiscally responsible generation',
    //     name: 'Pranav Chaturvedi',
    //     testimonial: '"Raising kids is no easy task, especially now with tech and everything else changing every minute; it really does take a village. Good Good Piggy will be a game-changing tool that helps shape a more fiscally responsible generation. It teaches personal responsibility and financial skills at an early age so it really makes a difference throughout their life."',
    //     city: 'Founding Partner, Favcy Venture Builders',
    //     image: pranav,
    //     alt: 'Imageone',
        
    // },
    // {   
    //     heading:'Good Good Piggy keeps my child motivated',
    //     name: 'Sonal Mittal',
    //     testimonial: '"I like Good Good Piggy because it keeps the drama about drinking milk and putting away the toys to a minimum. Good Good Piggy keeps my child motivated to do these things with no complaints and I dont need to tell any parent what a relief that is! All this while silently teaching patience with money. Effective parenting tool!"',
    //     city: 'Product Head, Flurry Analytics Former Head of Product, TechCrunch',
    //     image: sonal,
    //     alt: 'Imageone',
        
    // },
    {   
        heading:'Works on their behavioural and money values with diligence',
        name: 'Sumit Mehta',
        testimonial: '"Good Good Piggy is a much needed product considering money is becoming digitised and slowly invisible. It not only instills financial acumen into kids at an early age but also works on their behavioural and money values with diligence. Looking forward to a product that will help parents in raising responsible kids!"',
        city: 'Managing Director, Arrow Capital Ex- Daman Investments &  ABN AMRO Bank',
        image: Sumit,
        alt: 'Imageone',
        
    },
    // {   
    //     heading:'Really happy to have Good Good Piggy on my side',
    //     name: 'Yamika Mehra',
    //     testimonial: '"As a mother to a 5 year old who is growing up in the age of swipe right Amazon shopping, I am determined to instill the understanding of money and good money habits in him right from the beginning. While doing that, I realised that there arent any tools to really help me do this with discipline. All I have are stars drawn out on paper stuck on to my refrigerator 😳. So really happy to have Good Good Piggy on my side and its exciting features to help me tread this tricky road🤩"',
    //     city: 'Partner, 1stCheque - Angel Investment Platform',
    //     image: Yamika,
    //     alt: 'Imageone',
        
    // }
]