import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import Backdrop from "../Backdrop";
import SideDrawer from "./SideDrawer";
import { navbarList } from "../../assets/data/NavData";
import { Link } from "react-router-dom";
import Logo from '../../assets/images/logo.png'

import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { IoMenuOutline, IoHomeOutline } from "react-icons/io5";
import { BiHeart, BiShoppingBag, BiUser } from "react-icons/bi";

const MobileNavbar = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const OpenDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

      <SideDrawer show={drawerIsOpen}>
        <div className="mobile-navbar">
          <nav className="mobile-navigation-menu">
            <div className="menu-top title">
              <h2 className="menu-title">Menu</h2>

              <button className="menu-close-btn" onClick={closeDrawerHandler}>
                <FaTimes />
              </button>
            </div>

            <ul className="mobile-menu-category-list">
              <li className="menu-category">
                <Link
                  to="/"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Home
                </Link>
              </li>
              <li className="menu-category">
                <Link
                  to="/about"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  About Us
                </Link>
              </li>
              
              <li className="menu-category">
                <Link
                  to="/books"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Books
                </Link>
              </li>
              {/* <li className="menu-category">
                <Link
                  to="#"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Investors Interest
                </Link>
              </li>

              <li className="menu-category">
                <Link
                  to="#"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Collaborate
                </Link>
              </li> */}

              {/* {navbarList.map((item, index) => {
                return (
                  <>
                    <li
                      className="menu-category"
                      onClick={() => toggle(index)}
                      key={index}
                    >
                      <button className="accordion-menu">
                        <p className="menu-title">{item.catname}</p>

                        <div>
                          {clicked === index ? (
                            <FiMinus className="cat-icon" />
                          ) : (
                            <FiPlus className="cat-icon" />
                          )}
                        </div>
                      </button>

                      {clicked === index ? (
                        <ul className="submenu-category-list">
                          {item.subcategory.map((subcat, index) => {
                            return (
                              <>
                                <li className="submenu-category">
                                  <Link
                                    to="/"
                                    className="submenu-title"
                                    onClick={closeDrawerHandler}
                                    key={index}
                                  >
                                    {subcat.subcatname}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      ) : null}
                    </li>
                  </>
                );
              })} */}

              <li className="menu-category">
                <Link
                  to="/about-app"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  App
                </Link>
                {/* <a href="https://play.google.com/store/search?q=good%20good%20piggy&c=apps&hl=en&gl=US" target="_blank" className="menu-title"
                  onClick={closeDrawerHandler} >App</a> */}
              </li>
              <li className="menu-category">
                <Link
                  to="/contact-us"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Contact Us
                </Link>
              </li>

              {/* <li className="menu-category">
                <Link
                  to="#"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Events
                </Link>
              </li> */}
              {/* <li className="menu-category">
                <Link
                  to="#"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Career
                </Link>
              </li> */}
            </ul>

            <div>
              <ul className="mobile-social-container">
                <li>
                  <a href="#" className="social-link">
                    {/* <BsFacebook /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsTwitter /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsInstagram /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsLinkedin /> */}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </SideDrawer>

      <div className="mobile-navbar-heading">
        <p className="main-navigation__title">
          <Link to="/"><img src={Logo} alt="logo" width="80" height="70" /></Link>
        </p>

        <div className="mobile-bottom-navigation">
          <button className="action-btn" onClick={OpenDrawerHandler}>
            <IoMenuOutline />
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
