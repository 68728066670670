import React from "react";
import { Link } from "react-router-dom";
import Phones from "../../assets/images/ggp/banner-app.png";
import apple from '../../assets/images/ggp/apple.png';
import google from '../../assets/images/ggp/google.png';

const Banner = () => {
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 banner-left">
            
              <h1 className="banner-heading">
              <span>Good Good Piggy</span> <br/>Where learning and money management unite
              </h1>
              

              <div className="download-btn-group">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goodgoodpiggy"
                  target="_blank"
                  className="banner-btn"
                >
                  <div className="market-img-div">
                    <img src={google} className="img-fluid play-store"/>
                  </div>
                  {/* <div className="market-content-div">
                    <p className="tagline">GET IT ON</p>
                    <p className="headline">Play Store</p>
                  </div> */}
                </a>

                <a
                  href="https://apps.apple.com/app/good-good-piggy/id1663820984"
                  target="_blank"
                  className="banner-btn"
                >
                  <div className="market-img-div">
                    <img src={apple} className="img-fluid app-store"/>
                  </div>
                  {/* <div className="market-content-div">
                    <p className="tagline">GET IT ON</p>
                    <p className="headline">App Store</p>
                  </div> */}
                </a>
              </div>

              <div className="lang-para">
                <p className="para-desc">Now available in Hindi & English</p>
              </div>

             

              {/* 
              <Link to="#" className="banner-btn">
                      
                    </Link> */}
            </div>
            <div className="col-lg-6 col-12 banner-right">
              <img
                src={Phones}
                className="banner-img-fluid"
                width={80}
                height={80}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
