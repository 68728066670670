import React, { useState, useEffect } from "react";
import Button from "../Button";

const Community = () => {
  const [zohoForm, setZohoForm] = useState(`<html>
  <head>
      <meta charset="UTF-8">
      <title>Join&#x20;the&#x20;most&#x20;exclusive&#x20;online&#x20;community&#x20;for&#x20;parents&#x20;and&#x20;kids&#x20;today&#x21;</title>
<!-- <link href="css/form.css" rel="stylesheet" type="text/css"> -->
<!-- <script src="js/validation.js"></script> -->
</head><body class="zf-backgroundBg"><!-- Change or deletion of the name attributes in the input tag will lead to empty values on record submission-->
<div class="zf-templateWidth"><form action='https://forms.zohopublic.in/goodgoodpiggy/form/Website/formperma/7-liiQtbnsfW_dLDp7dji3U02Fc1Pohyh6V20PsAYxI/htmlRecords/submit' name='form' method='POST' onSubmit='javascript:document.charset="UTF-8"; return zf_ValidateAndSubmit();' accept-charset='UTF-8' enctype='multipart/form-data' id='form'><input type="hidden" name="zf_referrer_name" value=""><!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
<input type="hidden" name="zf_redirect_url" value=""><!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
<input type="hidden" name="zc_gad" value=""><!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
<div class="zf-templateWrapper"><!---------template Header Starts Here---------->
<ul class="zf-tempHeadBdr"><li class="zf-tempHeadContBdr"><h2 class="zf-frmTitle"><em>Join the most exclusive online community for parents and kids today!</em></h2>
<p class="zf-frmDesc"></p>
<div class="zf-clearBoth"></div></li></ul><!---------template Header Ends Here---------->
<!---------template Container Starts Here---------->
<div class="zf-subContWrap zf-topAlign"><ul>
<!---------Name Starts Here----------> 
<li class="zf-tempFrmWrapper zf-name zf-namemedium"><label class="zf-labelName"> 
Name 
<em class="zf-important">*</em>
</label>
<div 
class="zf-tempContDiv zf-twoType"  
>
<div
class="zf-nameWrapper"  
>
<span> <input type="text" maxlength="255" name="Name_First" fieldType=7 placeholder=""/> <label>First Name</label>
</span> 
</span> </span>
<span> <input type="text" maxlength="255" name="Name_Last" fieldType=7 placeholder=""/> <label>Last Name</label>
</span> 
</span> </span>
<div class="zf-clearBoth"></div></div><p id="Name_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
</div><div class="zf-clearBoth"></div></li><!---------Name Ends Here----------> 
<!---------Phone Starts Here----------> 
<li  class="zf-tempFrmWrapper zf-small"><label class="zf-labelName"> 
Phone 
<em class="zf-important">*</em>
</label>
<div class="zf-tempContDiv zf-phonefld">
<div
class="zf-phwrapper zf-phNumber"  
>
<span> <input type="text" compname="PhoneNumber" name="PhoneNumber_countrycode" maxlength="20" checktype="c7" value="" phoneFormat="1" isCountryCodeEnabled=false fieldType="11" id="international_PhoneNumber_countrycode" valType="number" phoneFormatType="1" placeholder="" />
<label>Number</label> </span>
<div class="zf-clearBoth"></div></div><p id="PhoneNumber_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
</div><div class="zf-clearBoth"></div></li><!---------Phone Ends Here----------> 
<!---------Email Starts Here---------->  
<li class="zf-tempFrmWrapper zf-small"><label class="zf-labelName"> 
Email 
<em class="zf-important">*</em>
</label>
<div class="zf-tempContDiv">
<span> <input fieldType=9  type="text" maxlength="255" name="Email" checktype="c5" value="" placeholder=""/></span> <p id="Email_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
</div><div class="zf-clearBoth"></div></li><!---------Email Ends Here---------->  
<!---------Single Line Starts Here---------->
<li class="zf-tempFrmWrapper zf-small"><label class="zf-labelName"> 
Kid's Name 
</label>
<div class="zf-tempContDiv">
<span> <input type="text" name="SingleLine" checktype="c1" value="" maxlength="255" fieldType=1 placeholder=""/></span> <p id="SingleLine_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
</div><div class="zf-clearBoth"></div></li><!---------Single Line Ends Here---------->
<!---------Number Starts Here---------->
<li class="zf-tempFrmWrapper zf-small"><label class="zf-labelName"> 
Kid's Age 
</label>
<div class="zf-tempContDiv">
<span> <input type="text" name="Number" checktype="c2" value="" maxlength="18" placeholder=""/></span> <p id="Number_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
</div><div class="zf-clearBoth"></div></li><!---------Number Ends Here---------->
</ul></div><!---------template Container Starts Here---------->
<ul><li class="zf-fmFooter"><button class="zf-submitColor" >Submit</button></li></ul></div><!-- 'zf-templateWrapper' ends --></form></div><!-- 'zf-templateWidth' ends -->
<script type="text/javascript">var zf_DateRegex = new RegExp("^(([0][1-9])|([1-2][0-9])|([3][0-1]))[-](Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[-](?:(?:19|20)[0-9]{2})$");
var zf_MandArray = [ "Name_First", "Name_Last", "PhoneNumber_countrycode", "Email"]; 
var zf_FieldArray = [ "Name_First", "Name_Last", "PhoneNumber_countrycode", "Email", "SingleLine", "Number"]; 
var isSalesIQIntegrationEnabled = false;
var salesIQFieldsArray = [];</script>
<script>
  // $Id: $
function zf_ValidateAndSubmit(){
  if(zf_CheckMandatory()){
    if(zf_ValidCheck()){
      if(isSalesIQIntegrationEnabled){
        zf_addDataToSalesIQ();
      }
      return true;
    }else{		
      return false;
    }
  }else{
    return false;
  }
}
  function zf_CheckMandatory(){
  for(i = 0 ; i < zf_MandArray.length ; i ++) {
      var fieldObj=document.forms.form[zf_MandArray[i]];
      if(fieldObj) {  
          if(fieldObj.nodeName != null ){
            if ( fieldObj.nodeName=='OBJECT' ) {
              if(!zf_MandatoryCheckSignature(fieldObj)){
                zf_ShowErrorMsg(zf_MandArray[i]);
                 return false;
              }
            }else if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
             if(fieldObj.type =='file')
              { 
               fieldObj.focus(); 
               zf_ShowErrorMsg(zf_MandArray[i]);
               return false;
              } 
                      fieldObj.focus();
                      zf_ShowErrorMsg(zf_MandArray[i]);
                      return false;
            }  else if( fieldObj.nodeName=='SELECT' ) {// No I18N
                     if(fieldObj.options[fieldObj.selectedIndex].value=='-Select-') {
              fieldObj.focus();
              zf_ShowErrorMsg(zf_MandArray[i]);
              return false;
               }
            } else if( fieldObj.type =='checkbox' || fieldObj.type =='radio' ){
              if(fieldObj.checked == false){
                fieldObj.focus();
                zf_ShowErrorMsg(zf_MandArray[i]);
                return false;
                 } 
            } 
          }else{
            var checkedValsCount = 0;
          var inpChoiceElems = fieldObj;
            for(var ii = 0; ii < inpChoiceElems.length ; ii ++ ){
                  if(inpChoiceElems[ii].checked === true ){
                    checkedValsCount ++;
                  }
            }
            if ( checkedValsCount == 0) {
                inpChoiceElems[0].focus();
                zf_ShowErrorMsg(zf_MandArray[i]);
                return false;
               }
        }
    }
  }
  return true;
}
function zf_ValidCheck(){
  var isValid = true;
  for(ind = 0 ; ind < zf_FieldArray.length ; ind++ ) {
    var fieldObj=document.forms.form[zf_FieldArray[ind]];
      if(fieldObj) {
        if(fieldObj.nodeName != null ){
          var checkType = fieldObj.getAttribute("checktype"); 
          if( checkType == "c2" ){// No I18N
            if( !zf_ValidateNumber(fieldObj)){
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
          }
          }else if( checkType == "c3" ){// No I18N
            if (!zf_ValidateCurrency(fieldObj) || !zf_ValidateDecimalLength(fieldObj,10) ) {
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
          }
          }else if( checkType == "c4" ){// No I18N
            if( !zf_ValidateDateFormat(fieldObj)){
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
          }
          }else if( checkType == "c5" ){// No I18N
            if (!zf_ValidateEmailID(fieldObj)) {
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
          }
          }else if( checkType == "c6" ){// No I18N
            if (!zf_ValidateLiveUrl(fieldObj)) {
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
            }
          }else if( checkType == "c7" ){// No I18N
            if (!zf_ValidatePhone(fieldObj)) {
            isValid = false;
            fieldObj.focus();
            zf_ShowErrorMsg(zf_FieldArray[ind]);
            return false;
            }
          }else if( checkType == "c8" ){// No I18N
            zf_ValidateSignature(fieldObj);
          }
        }
      }
  }
         return isValid;
}
function zf_ShowErrorMsg(uniqName){
  var fldLinkName;
  for( errInd = 0 ; errInd < zf_FieldArray.length ; errInd ++ ) {
    fldLinkName = zf_FieldArray[errInd].split('_')[0];
    document.getElementById(fldLinkName+"_error").style.display = 'none';
  }
  var linkName = uniqName.split('_')[0];
  document.getElementById(linkName+"_error").style.display = 'block';
}
function zf_ValidateNumber(elem) {
   var validChars = "-0123456789";
   var numValue = elem.value.replace(/^\s+|\s+$/g, '');
   if (numValue != null && !numValue == "") {
     var strChar;
     var result = true;
     if (numValue.charAt(0) == "-" && numValue.length == 1) {
       return false;
     }
     for (i = 0; i < numValue.length && result == true; i++) {
       strChar = numValue.charAt(i);
       if ((strChar == "-") && (i != 0)) {
         return false;
       }
       if (validChars.indexOf(strChar) == -1) {
         result = false;
       }
     }
     return result;
   } else {
     return true;
   }
 }
 function zf_ValidateDateFormat(inpElem){
   var dateValue = inpElem.value.replace(/^\s+|\s+$/g, '');
   if( dateValue == "" ){
     return true;
   }else{
    return( zf_DateRegex.test(dateValue) );
  }
 }
 function zf_ValidateCurrency(elem) {
   var validChars = "0123456789."; 
   var numValue = elem.value.replace(/^\s+|\s+$/g, '');
   if(numValue.charAt(0) == '-'){
     numValue = numValue.substring(1,numValue.length);
   }
   if (numValue != null && !numValue == "") {
     var strChar;
     var result = true;
     for (i = 0; i < numValue.length && result == true; i++) {
       strChar = numValue.charAt(i);
       if (validChars.indexOf(strChar) == -1) {
         result = false;
       }
     }
     return result;
   } else {
     return true;
   }
 }
 function zf_ValidateDecimalLength(elem,decimalLen) {
   var numValue = elem.value;
   if (numValue.indexOf('.') >= 0) {
     var decimalLength = numValue.substring(numValue.indexOf('.') + 1).length;
     if (decimalLength > decimalLen) {
       return false;
     } else {
       return true;
     }
   }
   return true;
 }
 function zf_ValidateEmailID(elem) {
      var check = 0;
      var emailValue = elem.value;
      if (emailValue != null && !emailValue == "") {
          var emailArray = emailValue.split(",");
          for (i = 0; i < emailArray.length; i++) {
              var emailExp = /^[\w]([\w\-.+&'/]*)@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,22}$/;
              if (!emailExp.test(emailArray[i].replace(/^\s+|\s+$/g, ''))) {
                  check = 1;
              }
          }
          if (check == 0) {
              return true;
          } else {
              return false;
          }
      } else {
          return true;
      }
  }
  function zf_ValidateLiveUrl(elem) {
    var urlValue = elem.value;
  if(urlValue !== null && typeof(urlValue) !== "undefined") {
    urlValue = urlValue.replace(/^\s+|\s+$/g, '');
    if(urlValue !== "") {
          var urlregex = new RegExp("^((((h|H)(t|T)|(f|F))(t|T)(p|P)((s|S)?)://[-.\\w]*)|(((w|W){3}\\.)[-.\\w]+)|((\\w+)([-\\w]*)(\\.([-\\w]+))+))(/?)([-\\w.?,:'/\\\\+=&;%$#@()!~]*)?$"); // Same regex as website_field_url in security-regex.xml. But single backslash is replaced with two backslashes.
      return(urlregex.test(urlValue));
    }
      }
      return true;
  }
  function zf_ValidatePhone(inpElem){
      
      var ZFPhoneRegex = {
          PHONE_INTE_ALL_REG: /^[+]{0,1}[()0-9-. ]+$/,
          PHONE_INTE_NUMERIC_REG: /^[0-9]+$/,
          PHONE_INTE_CONT_CODE_ENABLED_REG: /^[(0-9-.][()0-9-. ]*$/,
          PHONE_USA_REG: /^[0-9]+$/,
          PHONE_CONT_CODE_REG: /^[+][0-9]{1,4}$/
      }
    var phoneFormat = parseInt(inpElem.getAttribute("phoneFormat")); 
    var fieldInpVal = inpElem.value.replace(/^\s+|\s+$/g, '');
    var toReturn = true ;
    if( phoneFormat === 1 ){
      if(inpElem.getAttribute("valType") == 'code'){
              var codeRexp = ZFPhoneRegex.PHONE_CONT_CODE_REG;
              if(fieldInpVal != "" && !codeRexp.test(fieldInpVal)){
             return false;
      }
      }else{
      var IRexp = ZFPhoneRegex.PHONE_INTE_ALL_REG;
      if(inpElem.getAttribute("phoneFormatType") == '2'){
        IRexp = ZFPhoneRegex.PHONE_INTE_NUMERIC_REG;
      }
       if (fieldInpVal != "" && !IRexp.test(fieldInpVal)) {
         toReturn = false;
         return toReturn;
       }
       }
     return toReturn;
    }else if( phoneFormat === 2 ){
      var InpMaxlength = inpElem.getAttribute("maxlength");
      var USARexp = ZFPhoneRegex.PHONE_USA_REG;
      if  ( fieldInpVal != "" && USARexp.test(fieldInpVal) &&  fieldInpVal.length == InpMaxlength ) {
      toReturn = true;
    }else if( fieldInpVal == "" ){
      toReturn = true;
    }else{
      toReturn = false;
    }
    return toReturn;
    }
  }

function zf_ValidateSignature(objElem) {
    var linkName = objElem.getAttribute("compname");
    var canvasElem = document.getElementById("drawingCanvas-"+linkName);
    var isValidSign = zf_IsSignaturePresent(objElem,linkName,canvasElem);
     var hiddenSignInputElem = document.getElementById("hiddenSignInput-"+linkName);
  if(isValidSign){
    hiddenSignInputElem.value = canvasElem.toDataURL();
  }else{
    hiddenSignInputElem.value = "";// No I18N
  }
  return isValidSign;
  }

  function zf_MandatoryCheckSignature(objElem){
    var linkName = objElem.getAttribute("compname");
    var canvasElem = document.getElementById("drawingCanvas-"+linkName);
    var isValid = zf_IsSignaturePresent(objElem,linkName,canvasElem);
  return isValid;
  }

  function zf_IsSignaturePresent(objElem,linkName,canvasElem){
     var context = canvasElem.getContext('2d'); // No I18N
     var canvasWidth = canvasElem.width;
     var canvasHeight = canvasElem.height;
     var canvasData = context.getImageData(0, 0, canvasWidth, canvasHeight);
     var signLen = canvasData.data.length;
     var flag = false;
     for(var index =0; index< signLen; index++) {
          if(!canvasData.data[index]) {
              flag =  false;
          }else if(canvasData.data[index]) {
            flag = true;
            break;
          }
     }
  return flag;
  }

function zf_FocusNext(elem,event) {  
   if(event.keyCode == 9 || event.keyCode == 16){
      return;
    }
    if(event.keyCode >=37 && event.keyCode <=40){
       return;
    } 	
    var compname = elem.getAttribute("compname");
    var inpElemName = elem.getAttribute("name");
   if (inpElemName == compname+"_countrycode") { 
     if (elem.value.length == 3) {
       document.getElementsByName(compname+"_first")[0].focus();
     }
   } else if (inpElemName == compname+"_first" ) { 
     if (elem.value.length == 3) {
       document.getElementsByName(compname+"_second")[0].focus();
     }
   }
}

</script>
</body></html>`);

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    kidName: "",
    kidAge: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      const res = await fetch(
        "https://react-unwanted-default-rtdb.firebaseio.com//quote.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formValues,
          }),
        }
      );
      if (res) {
        alert("done");
        resetInput();
      } else {
        alert("some error");
      }
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
  };
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (
      !/^[a-z A-Z0-9]+$/.test(values.fullName) ||
      /^[ ]+$/.test(values.fullName)
    ) {
      errors.name = "Name is not valid";
    }

    if (!values.fullName) {
      errors.fullName = "Name is Required";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    return errors;
  };
  return (
    <section className="community">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12 comm-left">
            <h1 className="banner-heading">Join our Good Good Community</h1>
            {/* <p className="banner-text">
              sdf ggrtsr eruthguirghghbrt r trhg r grgh
            </p> */}
          </div>
          <div className="col-lg-6 col-12 comm-right">
            <div className="enquiry text-left">
              <form>
              <iframe frameBorder="0" style={{height:'780px',width:'99%',border:'none'}} src='https://forms.zohopublic.in/goodgoodpiggy/form/Website/formperma/8EfPGuQnJ_rVjoZcWhLbuel2GBrlSqh7G9R5CppwV7Y'></iframe>
                <div className="form-row">
                  {/* <div className="form-group col-12 text-center">
                    <div dangerouslySetInnerHTML={{ __html: zohoForm }} />
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
