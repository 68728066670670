import React from "react";
import "./assets/scss/style.scss";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import MainNavigation from "./components/Navigation/MainNavigation";
import MobileNavbar from "./components/Navigation/MobileNavbar";
import Topbar from "./components/Topbar/Topbar";
import ScrollToTop from "./components/ScrollTop";
import Aboutnew from "./pages/Aboutnew";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import AppPage from "./pages/AppPage";
import Events from "./pages/Events";

import Books from "./pages/Books";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Topbar />
      <MainNavigation />
      <MobileNavbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <Aboutnew />
        </Route>
        <Route exact path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route exact path="/privacy-policy">
          <Privacy />
        </Route>
        <Route exact path="/events">
          <Events />
        </Route>
        <Route exact path="/contact-us">
          <Contact />
        </Route>
        <Route exact path="/books">
          <Books />
        </Route>
        <Route exact path="/about-app">
          <AppPage />
        </Route>
        <Route exact path="*">
          <NotFound />
        </Route>
      </Switch>
      <Footer />
      {/* </Suspense> */}
    </Router>
  );
}

export default App;
