import React from "react";

import styles from "./about.module.css";
import Title from "../components/Title/Title";

//images
import ceo from "../assets/images/about/ceo.png";
import pana from "../assets/images/about/pana.png";
import rafiki from "../assets/images/about/rafiki.png";
import Ourvalues from "../components/Ourvalues";
import pana1 from "../assets/images/about/pana1.png";
import cym from "../assets/images/ggp/about/challange.png";
import star from "../assets/images/ggp/about/clap.png";
import cf from "../assets/images/ggp/about/teamwork.png";
import home from "../assets/images/ggp/about/flexible.png";
import inclusive from "../assets/images/ggp/about/innovation.png";

const Aboutnew = () => {
  return (
    <>
      <div className="about-page">
        {/* banner */}
        <div className="about-banner">
          <div className="container">
            <div className="row about-banner-content">
              <div className="col-12">
                <h1>Get To Know Us Better</h1>
                <h4 style={{ color: "#fff" }}>
                  Experience our story, our work, and our unique way to raise
                  good good kids.
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* founder details */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="about-quality col-lg-6 col-md-6 col-12">
              <div className="about-quality-1">
                <h2>The Good Good Founder</h2>
                <p>
                  The Good Good Founder{" "}
                  <a
                    href="https://www.linkedin.com/in/purvaggarwal/"
                    target="_blank"
                    style={{color: '#B555C3',fontWeight: '600', fontSize: '18px'}}
                  >
                    Purva Aggarwal
                  </a>{" "}
                  is the first female soloprenuer in India and third globally to
                  enter the ‘children’s online banking & wallet’ space. Purva
                  was a Financial Analyst at a private equity investment
                  advisory firm in London and was a writer with a leading
                  publication for institutional investors. She is the Ambassador
                  of the Indian Chapter of Women in Tech- a global movement by
                  an international non-profit (headquartered in Paris, France)
                  dedicated to promoting greater diversity in tech sector
                  globally. She is also a researcher in the field of Mergers and
                  Acquisitions.
                </p>
                <p>
                  {" "}
                  Purva Aggarwal did MSc. Finance and Investment Banking from
                  the University of Hertfordshire. She was awarded prestigious
                  Dean Award ‘Rising Star’ as well as Gold award for
                  Employability and Enterprise Skills.
                </p>
                <p>
                  {" "}
                  Previously, Purva has worked in leading organizations such as
                  BDO India and Grant Thornton, delivering risk-based internal
                  audits, business process reviews, internal financial control
                  (IFC) reviews, and IT general control (ITGC) reviews. Her
                  expertise in strategy and business development were put to use
                  by management consultancies such as Sameer Mittal &
                  Associates, now taken over by Intertrust Group from the Nordic
                  Region. With{" "} 
                  <a
                    href="https://www.linkedin.com/company/good-good-piggy/"
                    target="_blank"
                    style={{color: '#B555C3',fontWeight: '600', fontSize: '18px'}}
                  >
                    Good Good Piggy
                  </a>{" "}, she is now venturing out with an
                  Ed-FinTech Startup.
                </p>
              </div>
            </div>
            <div className="about-image col-lg-6 col-md-6 col-12">
              <img src={ceo} alt="about-image img-fluid" />
            </div>
          </div>
        </div>

        {/* card - our misson */}

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card about-values-card">
                <div className="service-image-div">
                  <img src={pana} alt="" className="img-fluid" />
                </div>
                <div className="card-body p-3">
                  <h2>Our Mission</h2>
                  <p className="text-center">
                    Our mission is to enable parents to raise kids with good
                    habits and money management skills via an interactive, fun,
                    and comprehensive platform. We also aim to help create a
                    responsible and future-ready generation through our features
                    and offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card about-values-card">
                <div className="service-image-div">
                  <img src={rafiki} alt="" className="img-fluid" />
                </div>
                <div className="card-body p-3">
                  <h2>Our Vision</h2>
                  <p className="text-center">
                    Our vision is to create the best value-based ecosystem for
                    pre-teenagers to learn and practice good habits & money
                    skills in this digital era.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card about-values-card">
                <div className="service-image-div">
                  <img src={pana1} alt="" className="img-fluid" />
                </div>
                <div className="card-body p-3">
                  <h2>Our Work Culture</h2>
                  <p className="text-center">
                    Our work culture at GGP HQ is co-dependent on one of our
                    core values. Our culture is one that encourages, supports,
                    and celebrates our diversity and looks to expand and build
                    it constantly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* carosel  */}
        {/* <Ourvalues /> */}

        {/* more about us */}

        <div className="container mt-5 mb-5">
          <Title title="Perks and Benefits" />
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="about-card">
                <div className="about-card-heading">
                  <img className="img-fluid about-card-img" src={cym} alt="" />
                  <h2>Challenge your mind</h2>
                </div>
                <div className="about-card-content">
                  <p>
                    Challenge your mind, change your behaviour. We're on a
                    mission to drive meaningful change to redefine success for
                    the modern workforce.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="about-card">
                <div className="about-card-heading">
                  <img className="img-fluid about-card-img" src={star} alt="" />
                  <h2>Purpose & Prosperity</h2>
                </div>
                <div className="about-card-content">
                  <p>
                    Your work should make you happy. We want to create an
                    environment where everyone can give their best work and
                    prosper as they do it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="about-card">
                <div className="about-card-heading">
                  <img className="img-fluid about-card-img" src={home} alt="" />

                  <h2>Work Flexibly</h2>
                </div>
                <div className="about-card-content">
                  <p>
                    Unpredictable tasks and measures call for flexibility. Work
                    anywhere, anytime without geographical boundaries.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="about-card">
                <div className="about-card-heading">
                  <img
                    className="img-fluid about-card-img"
                    src={inclusive}
                    alt=""
                  />
                  <h2>Inclusive & Diverse Environment</h2>
                </div>
                <div className="about-card-content">
                  <p>
                    Diversity is our strength. It leads to innovation, new ideas
                    and a better way of working together. We’re a team that does
                    not differentiate on basis of caste, religion, gender, age
                    or sexuality.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="about-card">
                <div className="about-card-heading">
                  <img className="img-fluid about-card-img" src={cf} alt="" />

                  <h2>Cross Function & Collaborate</h2>
                </div>
                <div className="about-card-content">
                  <p>
                    We administer an environment where employees can work
                    together and become a high performing team by collaborating,
                    sharing knowledge, and by supporting each other.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <div className="col-lg-6 col-md-6 col-12">
              <iframe
                className="youtube-embed"
                width="100%"
                height="410px"
                src="https://www.youtube.com/embed/sCE97j4nPK4"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <h1 style={{ color: "#B555C3" }} className={styles.yttext}>
                Hear It From The Good Good Founder
              </h1>
            </div>
          </div>
        </div>

        {/* youtube */}
      </div>
    </>
  );
};

export default Aboutnew;
