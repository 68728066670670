import React from "react";
import Title from "./Title/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const YoutubeReview = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="mt-3">
        <Title title="Hear what the Good Good Community has to say" />

        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                <div className="row">
                  <div className="m-3 youtube-card card bg-white border-1 rounded-2 text-center services-card about-values-card">
                    <div className="service-image-div">
                      <iframe
                        className="youtube-embed"
                        width="100%"
                        height="410px"
                        src="https://www.youtube.com/embed/BxbMaZZFims"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="card-body p-3">
                      <p className="text-center youtube-review-text">
                        Vidushi Rajoria, Director - Little Cambridge Early
                        Learning Center (Jasola and New Friends Colony)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="m-3 youtube-card card bg-white border-1 rounded-2 text-center services-card about-values-card">
                    <div className="service-image-div">
                      <iframe
                        className="youtube-embed"
                        width="100%"
                        height="410px"
                        src="https://www.youtube.com/embed/IwwHrobKGC0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="card-body p-3">
                      <p className="text-center youtube-review-text">
                        Payal Mansukhani, GD Goenka La Petite GK 1
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="m-3 youtube-card card bg-white border-1 rounded-2 text-center services-card about-values-card">
                    <div className="service-image-div">
                      <iframe
                        className="youtube-embed"
                        width="100%"
                        height="410px"
                        src="https://www.youtube.com/embed/FF_hRqEizSY"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="card-body p-3">
                      <p className="text-center youtube-review-text">
                        Good Good Piggy transforming families into Money Smart
                        Families
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YoutubeReview;
