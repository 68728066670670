import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import AOS from "aos";
import Logo from "../assets/images/logo.png";
import Finkids from "../assets/images/ggp/finkids-logo.png";
import FinkidLogo from "../assets/images/ggp/finkids.png";

import apple from "../assets/images/ggp/apple.png";
import google from "../assets/images/ggp/google.png";

const Footer = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);
  return (
    <>
      <section className="footer" data-aos="zoom-in" data-aos-duration="700">
        <footer className="footer-top">
          <div className="container">
            <div className="row">
              <div className="footer-col col-lg-3 col-md-6 col-sm-6 col-12 sm-mb-3">
                {/* <h3 className="main-heading">WOM SOLUTIONS</h3> */}
                <img src={Logo} width="100" height="95" />
                <p>
                  Good Good Piggy empowers parents to raise future ready kids by
                  helping them learn personal finance and be responsible from an
                  early age.
                </p>
                <div className="social-links mb-4">
                  <a
                    href="https://www.facebook.com/goodgoodpiggy"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://www.instagram.com/goodgoodpiggy.app/?hl=en"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/good-good-piggy/"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                  <a href="https://twitter.com/GoodGoodPiggy_" target="_blank">
                    <FaTwitter />
                  </a>
                </div>
                <div className="download-btn-group-footer">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.goodgoodpiggy"
                    target="_blank"
                    className="banner-btn"
                  >
                    <div className="market-img-div">
                      <img src={google} className="img-fluid play-store" />
                    </div>
                    {/* <div className="market-content-div">
                    <p className="tagline">GET IT ON</p>
                    <p className="headline">Play Store</p>
                  </div> */}
                  </a>

                  <a
                    href="https://apps.apple.com/app/good-good-piggy/id1663820984"
                    target="_blank"
                    className="banner-btn"
                  >
                    <div className="market-img-div">
                      <img src={apple} className="img-fluid app-store" />
                    </div>
                    {/* <div className="market-content-div">
                    <p className="tagline">GET IT ON</p>
                    <p className="headline">App Store</p>
                  </div> */}
                  </a>
                </div>
              </div>
              {/* <div className="footer-col col-lg-2 col-md-6 col-sm-6 col-12">
                <h4>Company</h4>
                <ul>
                  <li>
                    <NavLink to="#">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="#">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="#">Investors Interest</NavLink>
                  </li>
                  <li>
                    <NavLink to="#">Collaborate</NavLink>
                  </li>
                  <li>
                    <NavLink to="#">Careers</NavLink>
                  </li>
                </ul>
              </div> */}
              <div className="footer-col col-lg-3 col-md-6 col-sm-6 col-12">
                <h4 style={{ fontFamily: "cookies", letterSpacing: "2px" }}>
                  Resources
                </h4>
                <ul>
                  <li>
                    <a href="#blog">Blog</a>
                  </li>
                  <li>
                    <a href="#faq">FAQ's</a>
                  </li>
                  {/* <li>
                    <a
                      href="https://drive.google.com/drive/u/2/folders/1HH4AAdph_1J5CbGMcXYJiR4ZlnaYOyqI"
                      target="_blank"
                    >
                      Media
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="">Download 100+ Stickers</a>
                  </li> */}
                </ul>
              </div>
              <div className="footer-col col-lg-3 col-md-6 col-sm-6 col-12">
                <h4 style={{ fontFamily: "cookies", letterSpacing: "2px" }}>
                  Legal
                </h4>
                <ul>
                  <li>
                    <NavLink to="/terms-and-conditions" exact>
                      Terms and Conditions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy" exact>
                      Privacy Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-lg-3 col-md-6 col-sm-6 col-12">
                <h4 style={{ fontFamily: "cookies", letterSpacing: "2px" }}>
                  Get in Touch
                </h4>
                <div className="contact-box mt-10">
                  <div className="contact-details">
                    <a href="mailto:hello@goodgoodpiggy.com">
                      <b>Email</b>: hello@goodgoodpiggy.com
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-10">
                  <div className="contact-details">
                    <a href="mailto:support@goodgoodpiggy.com">
                      <b>Already a User? Contact us on</b>:
                      support@goodgoodpiggy.com
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <a href="tel:919144792447">
                      <b>Phone</b>: +91 9144792447
                    </a>
                  </div>
                </div>
                {/* <div className="contact-box mt-30">
                  <div className="contact-details">
                    <a href="tel:919144792447
" >
                    <b>HotLine Number</b>:
                      +91 9144792447

                    </a>
                  </div>
                </div> */}
                <div className="contact-box mt-30">
                  <div
                    style={{
                      backgroundColor: "transparent",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      color: "#333",
                    }}
                  >
                    {" "}
                    <img
                      src={FinkidLogo}
                      className="finkids-img"
                      alt="Fin Kids"
                      width={125}
                      height={55}
                    />
                    <p
                      style={{
                        color: "#FFFFFF",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      {" "}
                      <b>FinKids Innovations Private Limited</b>
                    </p>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <p className="need-help-text cin-no">
                      <b>CIN </b>: U67100DL2021PTC379888
                    </p>

                    <p className="need-help-text">
                      {/* <b>Corp. Off. </b>: Building 10, 3rd Floor, Community
                      Centre, East of Kailash, New Delhi 110065 */}

                      <b>Address </b>: 172, 3rd Floor, Kailash Hills, New Delhi 110065
                    </p>

                    {/* <p className="need-help-text cin-no">
                      <b>Regd. Off. </b>: 172, 3rd Floor, Kailash Hills, East of
                      Kailash, New Delhi 110065
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  © 2023 FinKids Innovations Private Limited
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
