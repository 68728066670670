import React from "react";

const Privacy = () => {
  return (
    <>
      <div align="justify" style={{ margin: "5% 7.5% ", width: "85%" }}>
        <h3 style={{ fontWeight: "normal" }}>Privacy Policy</h3>
        <h5>WHAT DO WE DO WITH YOUR INFORMATION ?</h5>
        <p>
          When you use the services provided by "Good Good Piggy" (hereinafter
          referred to "GGP"), as part of the registration and subscription
          process, we collect the personal information you give us such as your
          name, address and email address as well as the Underage Beneficiaries
          name. When you browse our App, we also automatically receive your
          Device's internet protocol (IP) address in order to provide us with
          information that helps us learn about your Device.
        </p>
        <br />

        <h6>We use your information for the following purposes:</h6>
        <p>
          1. Enabling our Services
          <br />
          2. Customer Support
          <br />
          3. Legal and Regulatory Compliances
          <br />
          4. Research and Development
          <br />
          5. For marketing and outreach
        </p>

        <br />

        <h5>EMAIL & WHATSAPP MARKETING</h5>
        <p>
          With your permission, we may send you emails and whatsapp
          notifications about our services, new products and other updates.
        </p>

        <br />
        <br />

        <h5>CUSTOMER CONSENT</h5>
        <p>
          When you provide us with personal information to complete a
          transaction, verify your credit card, register on the GGP App,
          subscribe to our services, we imply that you consent to our collecting
          it and using it for that specific reason and communication (Calling,
          Whatsapp, E-mail and SMS). If after you opt-in, you change your mind,
          you may withdraw your consent for us to contact you, for the continued
          collection, use or disclosure of your information, at any time, by
          contacting us at hello@goodgoodpiggy.com.
        </p>

        <br />

        <h5>IN-APP PERMISSIONS</h5>

        <p>
          <b>1. Collection of Location Information</b>
        </p>
        <p>
          {" "}
          We collect, use, and safeguard your location data when you access our
          services and features that require location access. When you use GGP
          app, we may collect and process information about your location. You
          have the right to control the collection and use of your location
          information. You can typically manage location access through your
          device settings or permissions. However, disabling location services
          may limit the functionality and features of our services that rely on
          accurate location information.
        </p>
        <p>
          This permission for location information enables us to provide you
          with customized services, tailored content, and relevant information
          based on your geographical location. We may collect your location
          information through various technologies, including GPS, Wi-Fi, and IP
          address. We collect your location information for the following
          purposes:
        </p>
        <p>
          a. Personalized Services: We may use your location data to provide you
          with personalized services and features. For example, we can offer
          location-based recommendations, local weather updates, or relevant
          information based on your current location.
        </p>
        <p>
          b. Geolocation Services: If you enable location services, we may use
          your location data to provide specific features or services that
          require accurate geolocation information. This could include finding
          nearby businesses, tracking routes, or offering location-based
          promotions.
        </p>
        <p>
          {" "}
          c. Analytics and Improvements: We may analyze aggregated location data
          to better understand user behavior, enhance our services, and improve
          user experiences. This data helps us identify trends, optimize
          content, and make informed business decisions.
        </p>
        <p>
          Sharing and Disclosure of Location Information: We respect your
          privacy and do not sell or share your location information with third
          parties for their marketing purposes without your explicit consent.
          However, we may share your location data in the following
          circumstances:
        </p>
        <p>
          {" "}
          a. Service Providers: We may engage trusted third-party service
          providers who assist us in providing our services. These service
          providers may have access to your location data solely for the purpose
          of providing the requested services and are obligated to maintain its
          confidentiality.
        </p>
        <p>
          {" "}
          b. Legal Requirements: We may disclose your location information if
          required to do so by law or if we believe that such disclosure is
          necessary to protect our rights, comply with legal proceedings, or
          enforce our Terms of Service.
        </p>

        <p>
          <b>2. Collection of Information to send Notifications</b>
        </p>
        <p>
          We collect, use, and protect the personal information you provide to
          us when you give permission to send notifications. We are committed to
          safeguarding your privacy and ensuring the security of your personal
          information. By granting notification permissions, you acknowledge and
          consent to the practices described in this Privacy Policy. When you
          grant permission to send notifications, we may collect certain
          information, including but not limited to:
        </p>
        <p>
          a. Device Information: We may collect device-related information such
          as your device type, operating system, unique device identifiers,
          browser type, and IP address.
        </p>
        <p>
          b. Notification Preferences: We may collect data about your
          notification preferences, including the types of notifications you
          wish to receive.
        </p>
        <p>
          We use the information collected to provide you with notifications
          based on your preferences and enhance your user experience.
          Specifically, we may use your information for the following purposes:
        </p>

        <p>
          a. Notification Delivery: We will use your device information to send
          notifications to the appropriate device.
        </p>
        <p>
          b. Personalization: We may personalise the content of the
          notifications based on your preferences and usage patterns.
        </p>
        <p>
          c. Analytics and Improvements: We may analyse aggregated data to
          understand user trends, improve our services, and enhance the
          effectiveness of our notifications.
        </p>

        <p>
          We may utilise third-party services to assist us in delivering
          notifications to you. These service providers may have access to your
          personal information solely for the purpose of performing their
          services on our behalf and are obligated not to disclose or use it for
          any other purpose.
        </p>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, please note that no
          method of transmission over the Internet or electronic storage is 100%
          secure, and we cannot guarantee absolute security. You have the right
          to control and manage your notification preferences. You can modify
          your notification settings within the application or device settings.
          You may also choose to opt out of receiving notifications entirely.
          Our services are not intended for individuals under the age of 13. We
          do not knowingly collect personal information from children without
          parental consent. If you believe we have inadvertently collected
          personal information from a child, please contact us immediately, and
          we will take appropriate steps to remove such information.
        </p>

        <p>
          <b>
            3. Collection of Information when signing up with Apple or Google
          </b>
        </p>
        <p>
          We handle your data as per law when you sign in to our platform using
          your Google or Apple accounts. By using our sign-in services, you
          acknowledge that you have read and understood the terms of this
          policy. When you sign in to our platform using your Google or Apple
          account, we may collect and use certain information associated with
          your account to provide you with a seamless and personalised
          experience. This information may include:
        </p>
        <p>
          a. Name: We collect your name from your Google or Apple account to
          identify you on our platform.
        </p>
        <p>
          b. Email Address: We may retrieve your email address from your Google
          or Apple account to facilitate communication and send you important
          updates and notifications.
        </p>
        <p>
          c. Profile Picture: With your permission, we may access your profile
          picture to personalize your user experience on our platform.
        </p>

        <p>
          d. Unique Identifiers: We may collect unique identifiers associated
          with your Google or Apple account to securely authenticate your
          identity and prevent unauthorized access.
        </p>

        <p>
          <b>
            By signing in with your Google or Apple account, you grant us the
            following permissions:
          </b>
        </p>
        <p>
          a. Basic Profile Information: We request access to your basic profile
          information, including your name, email address, and profile picture.
          This information helps us create and personalize your user account on
          our platform.
        </p>

        <p>
          b. Email Address: We may request access to your email address to send
          you important notifications and updates about our services.
        </p>

        <p>
          c. Authentication: We may use your Google or Apple account credentials
          to authenticate your identity and ensure secure access to our
          platform.
        </p>

        <p>
          d. Public Profile: With your consent, we may access certain public
          information associated with your Google or Apple account, such as your
          public profile picture or publicly available details. This information
          helps us enhance your user experience by providing personalized
          content and recommendations.
        </p>

        <p>
          We take data security seriously and implement appropriate technical
          and organizational measures to protect your information from
          unauthorized access, alteration, disclosure, or destruction. We retain
          your data for as long as necessary to fulfill the purposes outlined in
          this Privacy Policy, or as required by law.
        </p>

        <p>
          We do not sell, trade, or share your personal information with third
          parties for their marketing purposes. However, we may engage trusted
          third-party service providers to assist us in operating our platform
          and delivering our services. These providers have limited access to
          your information and are bound by strict confidentiality obligations.
        </p>

        <p>
          a. Account Deletion: If you wish to delete your account and revoke the
          permissions granted during the sign-in process, please contact our
          support team at support@goodgoodpiggy.com. Please note that account
          deletion may result in the loss of your data and access to certain
          features.
        </p>

        <p>
          b. Privacy Settings: You may have control over certain privacy
          settings within your Google or Apple account, which can impact the
          information shared with our platform during sign-in. We encourage you
          to review and adjust these settings according to your preferences.
        </p>

        <p>
          <b>4. Collection of audio tools and storage </b>
        </p>

        <p>
          By accessing or using our audio tools and storage features, you
          consent to the practices described in this Privacy Policy. In order to
          provide you with audio tools and storage features, we may request
          access to your device's microphone or audio inputs. By granting us
          permission, you allow us to record, process, and use audio data to
          facilitate the functionality of our services. To store and manage
          audio files, we may require access to your device's storage or cloud
          storage accounts. Granting us permission enables us to securely save
          and organize your audio files on your behalf.
        </p>

        <p>
          a. Audio Data: The audio data we collect with your consent is used
          solely to provide you with the requested audio tools and storage
          services. This includes processing audio inputs, converting formats,
          storing files, and enabling playback or sharing functionalities.
        </p>

        <p>
          b. Personalization and Improvement: We may analyze anonymized and
          aggregated audio data to improve the performance, features, and user
          experience of our services. This data helps us understand usage
          patterns, identify bugs, develop new features, and enhance overall
          service quality. We do not use this data for targeted advertising
          purposes.
        </p>

        <p>
          Your audio files and related metadata are securely stored on our
          servers or in the authorized cloud storage accounts you connect to our
          services. We employ industry-standard security measures to protect
          your data against unauthorized access, loss, or alteration.
        </p>

        <p>
          We retain your audio data for as long as necessary to provide the
          audio tools and storage services you use. If you choose to delete your
          audio files or terminate your account, we will promptly remove your
          data from our active servers. However, please note that residual
          copies may remain in our backup systems for a limited period.
        </p>

        <p>
          We may engage trusted third-party service providers to assist us in
          delivering the audio tools and storage services. These providers may
          have access to your audio data only to the extent necessary to perform
          their services on our behalf and are obligated to maintain the
          confidentiality and security of your information.
        </p>

        <p>
          We may access, preserve, and disclose your audio data if required to
          do so by law or in good faith belief that such action is necessary to:
          (a) comply with a legal obligation; (b) enforce our terms of service
          or policies; (c) protect our rights, property, or safety; or (d)
          defend against legal claims.
        </p>

        <p>
          You have the right to grant or revoke permissions for audio and
          storage access on your device through its settings. Please note that
          revoking these permissions may limit or disable certain features of
          our audio tools and storage services.
        </p>

        <p>
          If you wish to delete your account and associated audio data, you can
          do so by following the instructions provided within our services or
          e-mailing us on support@goodgoodpiggy.com . Please note that this
          action is irreversible and will result in the permanent loss of your
          data.
        </p>

        <h5>DISCLOSURE</h5>
        <p>
          We may disclose your personal information if we are required by law to
          do so or if you violate our Terms of Service.
        </p>

        <br />

        <h5>PAYMENT</h5>
        <p>
          We use "FREECHARGE PAYMENT TECHNOLOGIES PRIVATE LIMITED” for
          processing payments. We do not store your card data on their servers.
          The data is encrypted through the Payment Card Industry Data Security
          Standard (PCI-DSS) when processing payment. Your purchase transaction
          data is only used as long as is necessary to complete your purchase
          transaction. After that is complete, your purchase transaction
          information is not saved.
          <br />
          <br />
          Our payment gateway adheres to the standards set by PCI-DSS as managed
          by the PCI Security Standards Council, which is a joint effort of
          brands like Visa, MasterCard etc.
          <br />
          <br />
          PCI-DSS requirements help ensure the secure handling of credit card
          information by our store and its service providers. For more insight,
          you may also want to read terms and conditions of Freecharge on
          https://www.freecharge.in/termsandconditions
        </p>

        <br />

        <h3 style={{ fontWeight: "normal" }}>InApp Reward Service</h3>

<h5>DISTRIBUTION AGENCY AGREEMENT </h5>

<p>
  This InApp Reward Service Terms of Use ("Agreement") is integrated
  into the Terms of Use of GoodGoodPiggy.com, effective as of [Effective
  Date]. Please read this Agreement carefully before participating in
  our InApp Reward Service.{" "}
</p>

<h5>Parties</h5>
<p>
  The terms "Distributor" and "Pine Labs" referred to in this Agreement
  collectively constitute the "Parties." The Distributor is FinKids
  Innovations Private Limited, and Pine Labs is Pine Labs Private
  Limited.{" "}
</p>

<h5>KYC Information</h5>
<p>
  GoodGoodPiggy.com (referred to as the "Distributor") shall provide
  Pine Labs with the necessary Know Your Client ("KYC") information as
  per the RBI Regulations Master Direction Know Your Customer (KYC)
  Direction, 2016. Both Parties acknowledge that KYC criteria may
  undergo regulatory changes and agree to adhere to Anti Money
  Laundering and Risk Management ("AML") processes specified by the RBI
  Master Directions under Section 6 of the Regulation. The Distributor
  undertakes to collect and maintain the required minimum details of
  each Holder of Gift PPIs, including first name, last name, mobile
  number, email address, and residential address, and to provide such
  details to Pine Labs or RBI as per the terms of this Agreement or upon
  their demand.{" "}
</p>
<h5>Commercial and Settlement Terms: </h5>
<p>
  The commercial and settlement terms of this Agreement are outlined in
  Annexure C. Pine Labs explicitly issues the Cards and facilitates the
  Services related to the redemption of Card values. It is important to
  note that Pine Labs is not involved in the direct sale of the Cards.{" "}
</p>
<p>
  By using the InApp Reward Service on GoodGoodPiggy.com, you agree to
  be bound by the terms of this Agreement and the overall Terms of Use
  of GoodGoodPiggy.com. If you have any questions or concerns, please
  contact our support team at support@goodgoodpiggy.com.{" "}
</p>
<p>hank you for being a valued member of GoodGoodPiggy</p>


        <h5>THIRD-PARTY SERVICES</h5>
        <p>
          In general, the third-party providers used by us will only collect,
          use and disclose your information to the extent necessary to allow
          them to perform the services they provide to us.
          <br />
          <br />
          However, certain third-party service providers, such as payment
          gateways and other payment transaction processors, have their own
          privacy policies in respect to the information we are required to
          provide to them for your purchase-related transactions. For these
          providers, we recommend that you read their privacy policies so you
          can understand the manner in which your personal information will be
          handled by these providers.
          <br />
          <br />
          In particular, remember that certain providers may be located in or
          have facilities that are located in a different jurisdiction than
          either you or us. So, if you elect to proceed with a transaction that
          involves the services of a third-party service provider, then your
          information may become subject to the laws of the jurisdiction (s) in
          which that service provider or its facilities are located.
          <br />
          <br />
          Once you leave our App or are redirected to a third-party website or
          application, you are no longer governed by this Privacy Policy or
          GGP's Terms of Service.
          <br />
          <br />
          When you click on links on our App, they may direct you away from the
          App. We are not responsible for the privacy practices of other sites
          and encourage you to read their privacy statements.
        </p>

        <br />

        <h5>SECURITY</h5>
        <p>
          To protect your personal information, we take reasonable precautions
          and follow industry-standard practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed. We implement reasonable security measures to protect your
          information from unauthorized access, alteration, disclosure, or
          destruction. However, no method of data transmission over the internet
          or electronic storage is completely secure, and we cannot guarantee
          absolute security.
        </p>

        <br />

        <h5>AGE OF CONSENT</h5>
        <p>
          By using the GGP App, you represent that you are at least the age of
          majority in your state of residence, or that you are the age of
          majority in your state of residence and you have given us your consent
          to allow any of your minor dependents to use the GGP App.
        </p>

        <br />

        <h5>CHANGES TO THIS PRIVACY POLICY</h5>
        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the "Privacy Policy" page of
          the GGP App. If our store is acquired or merged with another company,
          your information may be transferred to the new owners so that we may
          continue to sell products to you. We encourage you to review this
          policy periodically to stay informed about how we collect, use, and
          protect your location information.
        </p>

        <br />

        <h5>QUESTIONS AND CONTACT INFORMATION</h5>
        <p>
          If you would like to: access, correct, amend or delete any personal
          information we have about you, register a complaint, or simply want
          more information contact our Compliance Officer at
          hello@goodgoodpiggy.com.
        </p>
      </div>
    </>
  );
};

export default Privacy;
