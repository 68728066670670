import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//images
import one from "../assets/images/achivements/aic.png"
import two from "../assets/images/achivements/bsezone.png";
import three from "../assets/images/achivements/gusec.png";
import four from "../assets/images/achivements/maian.jpg";
import five from "../assets/images/ggp/brands/10.png";
import six from "../assets/images/achivements/StrongHer.webp";
// import seven from "../assets/images/about/value.png";




import styles from './achievements.module.css';
import Title from "./Title/Title";



const Achievements =()=> {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplayspeed: 3000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div className={styles.App}>
            <Title  title="Achievements"/>
            <Slider {...settings}>

                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={five}
                        />
                        
                    </div>
                    <p style={{fontSize:'12px'}}>Shark Tank India Season 1</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={one}
                        />
                       
                    </div>
                    <p style={{fontSize:'12px'}}>Top 100- NMIMS Atal Innovation Center</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={two}
                        />
                        
                    </div>
                    <p style={{fontSize:'12px'}}>Top 8- BSE's AcceleratHer with EntrepreNaari</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={three}
                        />
                        
                    </div>
                    <p style={{fontSize:'12px'}}>Top 10- GUSEC Techcelerate</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={four}
                        />
                        
                    </div>
                    <p style={{fontSize:'12px'}}>Special Commendation- Money Awareness & Inclusion Awards 2023</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={six}
                        />
                        
                    </div>
                    <p style={{fontSize:'12px'}}> Top 100- Arise Accelerator with StrongHer</p>
                </div>
                
                {/* <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={six}
                        />
                        <h3>Trust</h3>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardtop}>
                        <img
                            src={seven}
                        />
                        <h3>Value Centricity</h3>
                    </div>
                </div> */}



            </Slider>
        </div>
    );
}

export default Achievements;