import React from "react";
import Title from "../Title/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bluebells from "../../assets/images/institute/bluebells1.png";
import edm from "../../assets/images/institute/edm1.png";
import gdGoenka from "../../assets/images/institute/gd-goenka .png";
import gurukul from "../../assets/images/institute/gurukul1.png";
import indoAmerican from "../../assets/images/institute/indo-american.png";
import littleCamb from "../../assets/images/institute/little.jpeg";
import pacific from "../../assets/images/institute/pacific.png";
import sriRam from "../../assets/images/institute/sri-ram.png";
import Tots from "../../assets/images/institute/tots.png";


const Institutional = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="brands">
      <div
        style={{
          marginLeft: "5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Title title="Our Institutional Partners" />
       
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              <div className="row mb-4">
                <img
                  src={bluebells}
                  style={{ height: "65%", width: "65%", margin: "0% 25%" }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={edm}
                  style={{
                    height: "65%",
                    width: "65%",
                    margin: "0% 25%",
                    borderRadius: "10px",
                  }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={gdGoenka}
                  style={{
                    height: "65%",
                    width: "65%",
                    margin: "0% 30%",
                    borderRadius: "10px",
                  }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={gurukul}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                  style={{ height: "70%", width: "70%", margin: "0% 23%", backgroundColor: '#1f315f', padding: '5px' }}
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={indoAmerican}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={littleCamb}
                  style={{ height: "85%", width: "85%", margin: "0% 23%" }}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={pacific}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              <div className="row mb-4">
                <img
                  src={sriRam}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
              {/* {" "} */}
              <div className="row mb-4">
                <img
                  src={Tots}
                  className="img-fluid"
                  height="100"
                  width="150"
                  loading="lazy"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Institutional;
